.complain-form {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(49, 53, 61, 0.98);
	z-index: 1000;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateX(300vh);
	transition: all ease-in-out 0.5s;
	overflow: hidden;
	overflow-y: scroll;
	&::-webkit-scrollbar {
		display: none !important;
	}
	&.show {
		transition: all ease-in-out 0.6s;
		transform: translateX(0);
		.container {
			display: block;
		}
	}
	.container {
		width: 100%;
		padding: 1.5rem;
		.header {
			margin-bottom: 2rem;
			h2 {
				font-size: 2rem;
				line-height: 1.15;
				color: #fff;
			}
		}
		.form-wrapper {
			display: grid;
			grid-template-columns: repeat(1, 1fr);
			gap: 2rem;
			// padding-inline: 1.25rem;
			.field-wrapper {
				position: relative;
				.errorMsg {
					position: absolute;
					color: red;
					margin-inline-start: 1rem;
					transition: 0.3s all;
				}
				label {
					color: #000000;
					font-size: 1.2rem;
					display: none;
				}
				input {
					// height: 4rem;
					height: 3.5rem;
				}
				.input-wrapper {
					position: relative;
					padding-bottom: 0;
					.text-input {
						padding: 0.5rem 1rem;
						background: #f8f8f8;
						width: 100%;
						font-size: 1.1rem;
						border: solid 1px rgba($color: #000, $alpha: 0.1);
						border-radius: 4px;
						&:focus-visible {
							outline: none;
							border: solid 1px rgba($color: #31353d, $alpha: 0.7);
						}
						&::placeholder {
							color: rgba($color: #31353d, $alpha: 0.4);
							font-size: .75rem;
						}
					}
					textarea {
						resize: none;
					}
					&.input-wrapper--flex {
						display: flex;
						margin-right: 0.9rem;
					}
				}
				.field-wrapper-container {
					display: flex;
					align-items: center;
					flex-direction: row;
					padding: 1rem 0;
					&.field-wrapper-container--column {
						flex-direction: column;
						align-items: flex-start;
						padding-top: 0;
						p {
							padding-top: 1.5rem;
							color: #000000;
							font-size: 1.2rem;
						}
						.title {
							color: #5f5f5f;
						}
						.field-wrapper {
							display: flex;
							flex-direction: column;
							.field {
								display: none;
							}
							.file-input__label {
								border: 1px solid #2d477a;
								padding: 0.5rem 1rem;
								margin: 1rem 0;
								color: #2d477a;
								font-size: 1.4rem;
								cursor: pointer;
							}
						}
						span {
							color: #5f5f5f;
							font-size: 1.2rem;
						}
					}
				}
			}
			button {
				width: 12rem;
				&.formSubmitted {
					color: #31353d;
					background-color: #fff;
					pointer-events: none;
				}
			}
		}
	}
	.cancel-btn {
		position: absolute;
		right: 1.5rem;
		top: 1.5rem;
		cursor: pointer;
		border: 1px solid #fff;
		width: 2.5rem;
		height: 2.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		svg {
			width: 2rem;
			height: 2rem;
			path {
				fill: #fff;
			}
		}
	}
	.img-container {
		position: absolute;
		right: 30%;
		top: 50%;
		transform: translate(50%, -50%);
		width: 23rem;
		height: 15rem;
		display: none;
	}
	.thank-message {
		height: 24rem;
		padding-top: 8rem;
		color: #fff;
		font-size: 1.5rem;
	}
}

@media (min-width: 1024px) {
	.complain-form {
		.container {
			padding: 3rem 8.5rem;
			.header {
				margin-bottom: 4rem;
				padding-inline-start: 0;
				h2 {
					font-size: 3rem;
				}
			}
			.form-wrapper {
				grid-template-columns: repeat(1, 1fr);
				gap: 2.25rem;
				width: 45%;
				button {
					width: 9rem;
				}
				.field-wrapper{
					.input-wrapper{
						.text-input{
							&::placeholder {
								font-size: 1rem;
							}
						}
					}
				}
			}
		}
		.cancel-btn {
			right: 3rem;
		}
		.img-container {
			display: block;
		}
		.thank-message {
			height: 28.5rem;
			padding-top: 10rem;
			font-size: 2rem;
		}
	}
}

// For small-height devices
@media (max-width: 375px) and (max-height: 667px) {
	.complain-form {
		.container {
			.form-wrapper {
				.field-wrapper {
					.input-wrapper {
						input {
							height: 2.5rem;
						}
						textarea {
							height: 5rem;
						}
					}
				}
			}
		}
		.cancel-btn {
			top: 1.5rem;
		}
	}
}
