// Mobile Amenity Style
.amenity {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	// border: solid 1px rgba($color: #292c33, $alpha: 0.5);
	height: 19rem;
	transition: all ease-in-out 0.25s;
	position: relative;
	// box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
	cursor: default;
	.icon-container {
		width: 4.5rem;
		height: 5rem;
		position: relative;
		z-index: 1;
		margin-bottom: 1rem;
		img {
			transition: scale ease-in-out 0.25s;
			width: 50%;
			height: 80%;
		}
	}
	.amenity-info {
		display: flex;
		flex-direction: column;
		align-items: center;
		z-index: 1;
		padding-inline: 3rem;
		gap: 1rem;
		.title {
			font-size: 1.2rem;
			text-align: center;
		}

		.desc {
			// display: none;
			text-align: center;
			font-size: 0.8rem;
		}
	}
	.back-layer {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		opacity: 0;
		transition: scale ease-in-out 0.5s, opacity ease-out 0.1s;
		// background-color: #292c33;
	}
	&.promise {
		height: 13rem;
		// border: none;
		// background-color: #fff;
		// box-shadow: 1px 1px 14px 6px rgba(0, 0, 0, 0.08);
	}
	&.commercialServices-section {
		// height: 23rem;
		padding-inline: 0.5rem;
	}
	&.businessProject {
		height: 14rem;
	}
	.one {
		position: absolute;
		top: 0;
		left: 0;
		width: 0;
		height: 1px;
		background-color: rgba(0, 0, 0, 0.5);
		transition: 1.25s all;
		&.two {
			left: 0;
			height: 0;
			width: 1px;
		}
		&.three {
			top: unset;
			bottom: 0;
			width: 0;
			height: 1px;
		}
		&.four {
			left: unset;
			top: 0;
			right: 0;
			height: 0;
			width: 1px;
		}
	}
}

// Desktop Amenity Style
@media (min-width: 1024px) {
	.amenity {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border: unset;
		// border-bottom: solid 1px rgba($color: #292c331a, $alpha: 0.1);
		// border-inline-end: solid 1px rgba($color: #292c331a, $alpha: 0.1);
		height: 12rem;
		transition: all linear 0.25s;
		position: relative;
		box-shadow: unset;
		// pointer-events: none;
		&::after {
			content: "";
			position: absolute;
			right: 0;
			top: 0;
			width: 1px;
			height: 0;
			background-color: rgba($color: #292c331a, $alpha: 0.2);
			transition: 2.5s all linear;
		}
		&::before {
			content: "";
			position: absolute;
			bottom: 0;
			width: 0;
			height: 1px;
			background-color: rgba($color: #292c331a, $alpha: 0.2);
			transition: 2.5s all linear;
		}
		.icon-container {
			width: 3rem;
			height: 3rem;
			position: relative;
			z-index: 1;
			margin-bottom: unset;
			img {
				transition: all ease-in-out 0.4s;
				width: 50%;
				height: 80%;
			}
		}
		.amenity-info {
			transition: all ease-in-out 0.25s;
			display: flex;
			flex-direction: column;
			align-items: center;
			z-index: 1;
			margin-top: 1rem;
			padding-inline: 1rem;
			gap: unset;
			.title {
				font-size: 1.25rem;
				padding-bottom: 1rem;
				transition: all ease-in-out 0.25s;
			}

			.desc {
				display: none;
				text-align: center;
				font-size: 0.8rem;
			}
		}
		.back-layer {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			opacity: 0;
			transition: transform ease-in-out 0.5s, opacity ease-out 0.1s;
			scale: 1;
			transition: 0.5s all;
			background-color: #292c33;
		}
		&.promise {
			box-shadow: none;
			height: 12rem;
			border: none;
			background-color: #fff;
			&:hover {
				background-color: #fff;
				// .amenity-header {
				// 	display: block;
				// }
				.amenity-info {
					.title {
						display: block;
						color: #000;
					}
				}
				.icon-container {
					display: block;
					img {
						filter: none;
					}
				}
			}
		}
		&.commercialServices-section {
			// height: 16rem;
			height: 11rem;
			.icon-container {
				width: 50%;
				height: 50%;
			}
			.amenity-info {
				display: none;
			}
			&:hover {
				.amenity-info {
					display: flex;
					.title {
						display: flex;
						font-size: 1.2rem;
					}
				}
			}
		}
		&.businessProject {
			pointer-events: none;
			height: 12rem;
			.back-layer {
				display: none;
			}
			&:hover {
				background-color: transparent;
				.amenity-info {
					.title {
						display: block;
						color: #000;
					}
				}
				.icon-container {
					display: block;
					img {
						filter: none;
					}
				}
			}
		}
		&:hover {
			background-color: #292c33;

			.amenity-header {
				display: none;
			}
			.amenity-info {
				.title {
					display: none;
					color: #fff;
				}
				.desc {
					color: rgba($color: #fff, $alpha: 0.7);
					display: flex;
					text-align: center;
				}
			}
			.icon-container {
				display: none;
				img {
					filter: invert(99%) sepia(0%) saturate(0%) hue-rotate(201deg)
						brightness(109%) contrast(100%);
				}
			}

			.back-layer {
				opacity: 1;
			}
		}
	}
}
