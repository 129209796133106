.nav-bar-container {
	width: 100%;
	position: absolute;
	top: 0;
	z-index: 3;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	overflow: hidden;
	transition: 0.5s all;
}
@media (min-width: 1024px) {
	.nav-bar-container {
		padding-inline: 8.5rem;
	}
}
.stickybar {
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 10;
}
