.media-center-section {
	padding: 3rem 1.25rem;
	overflow: hidden;
	&.article-center--bg {
		background: #f7f7f7;
		padding: 3.75rem 0 4rem;
		.header {
			h3 {
				font-size: 2.8rem;
				padding-inline: 1.25rem;
				line-height: 1.1;
				margin-bottom: 2rem;
                margin-inline-start: -.3rem;
			}
		}
		.media-items {
			border-top: none;
			margin-top: 0;
			padding-top: 0;
		}
	}
	.select-container {
		position: relative;
		z-index: 10;
		
		&.mobile-select-container {
			display: grid;
			grid-template-columns: 1fr 0.6fr;
			gap: 1rem;
		}
	}
	.media-items {
		border-top: 1px solid rgba($color: #000000, $alpha: 0.1);
		margin-top: 1.5rem;
		padding-top: 2.5rem;
	}
}
@media (min-width: 1024px) {
	.media-center-section {
		padding-top: 6rem;
		padding-bottom: 3rem;
		padding-inline: 0;
		&.article-center--bg {
			background: #f7f7f7;
			padding: 5.75rem 0 3rem 3rem;
			.header {
				h3 {
					font-size: 3rem;
					padding: 0 8.5rem;
					margin-bottom: 3rem;
				}
			}
			.media-items {
				.media-container {
					margin-top: 0;
				}
			}
		}
		.search-section {
			padding-inline: 8.5rem;
			display: flex;
			align-items: center;
			justify-content: space-between;
			.media-types {
				display: flex;
				align-items: center;
				.type {
					margin-inline-end: 3rem;
					display: flex;
					flex-direction: column;
					align-items: center;
					.active-point {
						width: 6px;
						height: 6px;
						border-radius: 50%;
						background-color: #000000;
						margin-bottom: 1rem;
						opacity: 0;
						transition: all ease-in-out 0.25s;
					}
					.name {
						cursor: pointer;
						color: rgba($color: #000000, $alpha: 0.3);
						text-transform: uppercase;
						font-size: 0.9rem;
						font-weight: 600;
						transition: all ease-in-out 0.25s;
					}
					&.active {
						.active-point {
							opacity: 1;
						}
						.name {
							color: #000000;
						}
					}
				}
			}
			.select-container {
				width: 15rem;
			}
		}

		.media-items {
			margin-top: 3rem;
			padding-top: 3rem;
			.main-media-item {
				padding-inline: 8.5rem;
				display: grid;
				grid-template-columns: 0.7fr 1fr;
				gap: 5rem;
				.item-details {
					display: flex;
					flex-direction: column;
					gap: 5rem;
					.meta {
						display: flex;
						align-items: center;
						justify-content: space-between;
						font-weight: 600;
						font-size: 0.9rem;
						.media-type {
							display: flex;
							align-items: center;
							.active-point {
								width: 7px;
								height: 7px;
								border-radius: 50%;
								background-color: #000000;
								transition: all ease-in-out 0.25s;
								margin-inline-end: 0.5rem;
							}
							span{
								
							}
						}
					}
					.core-info {
						.title {
							font-size: 2rem;
							width: 80%;
							line-height: 1.1;
						}
						.desc {
							width: 85%;
							margin: 1rem 0;
							display: -webkit-box;
							-webkit-line-clamp: 5;
							-webkit-box-orient: vertical;
							overflow: hidden;
						}
						.read-more {
							font-weight: 600;
							font-size: 1.1rem;
							cursor: pointer;
						}
					}
				}
				.image-container {
					position: relative;
					height: 28rem;
				}
			}
			.media-container {
				padding-inline: 5.5rem;
				margin-top: 6rem;
				display: grid;
				grid-template-columns: repeat(3, 1fr);
				&.no-matching-data {
					grid-template-columns: 1fr;
					.no-matching-text {
						text-align: center;
						font-weight: 600;
						font-size: 1.75rem;
					}
				}
				&.decrease-margin{
					margin-top: 3rem;
				}
				.media-card{
					// padding-inline: 4rem;
				}
			}
		}
	}
}
