.facilities-card {
	display: flex;
	flex-direction: column;
    background-color: white;
    .img-container {
        position: relative;
        height: 14rem;
        margin-bottom: 2rem;
    }
    .title{
            font-size: 1.313rem;
            color: #414141;
            margin-bottom: 1rem;
            
            padding-inline: 1.25rem;
    }
    .description{
        width: 98%;
        padding-inline: 1.25rem;
        min-height: 9rem;
    }
}
@media (min-width: 1024px) {
	.facilities-card {
        background-color: unset;
        padding-bottom: unset;
		.img-container {
			width: 92%;
			height: 11rem;
		}
        .description{
            padding: 0;
            min-height: unset;
            -webkit-line-clamp: unset;
            font-size: .85rem;
            width: 85%;
        }
        .title{
            padding: 0;
        }
	}
}
