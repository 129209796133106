:lang(ar) {
	direction: rtl;
	.testimonials-section .testimonials-card .card-data .description {
		height: 12rem;
	}
	.swiper-button-prev {
		&::after {
			background-image: url("/icons/arrow--next.svg");
		}
	}
	.swiper-button-next {
		&::after {
			background-image: url("/icons/arrow--prev.svg");
		}
	}
	.black-arrow-prev {
		&::after {
			background-image: url("/icons/arrow--next-black.svg");
		}
	}
	.black-arrow-next {
		&::after {
			background-image: url("/icons/arrow--prev-black.svg");
		}
	}
	//mobile-menus
	.mobile-search-menu,
	.mobile-menu {
		left: 0;
		right: unset;
	}
	.mobile-search-menu {
		.burger-menu-container {
			right: unset;
			left: 0;
		}
		.title{
			right: 1rem;
			left: auto;
		}
	}
	//dropDown-menu
	.dev-menu
		.development-menu
		.react-tabs
		.tabs-container
		.tabs-header
		.react-tabs__tab-list {
		.react-tabs__tab--selected::after,
		li::after {
			left: unset;
			right: 0;
		}
	}
	//sticky bar icons
	.sticky-container .contact-container .icon-img,
	.subnav-container .contact-container .icon-img {
		transform: scaleX(-1);
	}
	//buttons
	.button--isi::before {
		left: 100%;
		right: unset;
		margin: -15px 0 0 2px;
		transform-origin: 100% 50%;
	}
	//footer
	.footer-section .links-container .links .quick:last-child {
		padding-top: 0rem;
	}
	//homePage-banner
	.banner-section-container .swiper .swiper-navigation-continer {
		left: unset;
		right: 1.25rem;
	}
	.banner-section-container .banner-wrapper .banner-text-data .text-subtitle{
		letter-spacing: 0px;
    font-size: 1.2rem;
    margin-bottom: 1rem;
	}
	.project-banner .banner-wrapper .banner-text-data .text-subtitle{
		letter-spacing: 0px;
		font-size: 1.2rem;
		margin-bottom: 1rem;
	}
	// .project-overview-section 
	.project-overview-section .project-data .text-subtitle {
		letter-spacing: 0px;
		font-size: 1.4rem; 
	}
	//homePage-ourPortfolio
	.portfolio-container .swiper-pagination-progressbar,
	.portfolio-container-mobile .swiper-pagination-progressbar {
		transform: rotate(180deg);
	}
	//homePage-update
	.updates-section
		.updates-swiper-section
		.swiper
		.swiper-slide
		.update-card
		.img-conatiner {
		.date,
		.tag {
			left: unset !important;
			right: 1.5rem !important;
		}
	}
	.updates-section-mobile .swiper .swiper-img {
		.tag,
		.date {
			left: unset;
			right: 1.5rem;
		}
	}
	.updates-section-mobile .updates-header span{
		letter-spacing: 0;
		font-size: 1.2rem;
	}
	//project
	.masterplan-section {
		// .swiper-navigation-continer {
		// 	left: 8.5rem;
		// 	right: unset;
		// }
		.header { 
			right: 0;
		}
	}
	//project
	.masterplan-main-card .close-btn {
		right: unset;
		left: 1.5rem;
	}
	//projectPhase
	.construction-updates-section .header {
		left: unset;
		right: 0;
	}
	.construction-updates-section .swiper.thumbs-swiper {
		left: unset;
		right: 0;
	}
	.construction-updates-section .play-button {
		left: 50%;
		right: unset;
	}
	.construction-updates-section .play-button .icon-container svg.playBtn {
		transform: scale(0.8) scaleX(-1);
	}
	.project-banner .swiper .swiper-data {
		left: unset;
		right: 0;
	}
	//article page
	.ArticleInfo-container {
		.ArticleInfo-wrapper {
			.data {
				.title {
					h3 {
						&::before {
							left: auto;
							right: 0;
						}
					}
				}
			}
		}
	}

	// Testimonials Section
	.testimonials-section {
		.testimonials-card {
			.img-section {
				.img-container {
					transform: translateX(-0.5rem);
				}
			}
			.card-data {
				.position{
					letter-spacing: 0;
					font-size: 1.4rem;
				}
			}
		}
	}

	// PHASE >> About Card
	.about-card {
		.left-container {
			span{
				letter-spacing: 0;
				font-size: 1.4rem;
			}
			.card-image {
				.small-img-container {
					right: unset;
					left: 0;
				}
			}
			.card-data{
				span{
					letter-spacing: 0;
					font-size: 1.2rem;
				}
			}
		}
	}
	.phase-banner .banner-text-data .text-subtitle{
		letter-spacing: 0;
		font-size: 1.2rem;
	}
 
 
	// Complain Form
	.complain-form {
		.cancel-btn {
			left: 1.5rem;
			right: unset;
		}
	}
	.testimonials-section .swiper-navigation-continer{
		bottom: 4.5rem; 
	}

	// business-district Page

	.building-card .card-data{
		padding: 0 0.875rem 1.5rem; 
	}
	.building-card .card-img-container{
		padding: 1.5rem 0 1.5rem 1.5rem;
	}
	.building-card .card-data h3{
		top: -4rem;
	} 
	.banner-section-container .banner-wrapper .swiper-navigation-continer,
	.project-banner .banner-wrapper .swiper-navigation-continer{
		justify-content: flex-start; 
	}
	@media (min-width: 1024px) {
		.testimonials-section .testimonials-card .card-data .description {
			height: 8rem;
		}
		//swiper-arrows
		.swiper-button-prev {
			&:hover {
				&:after {
					background-image: url("/icons/arrow--next-black.svg");
				}
			}
		}
		.swiper-button-next {
			&:hover {
				&:after {
					background-image: url("/icons/arrow--prev-black.svg");
				}
			}
		}
		.black-arrow-prev {
			&:hover {
				&:after {
					background-image: url("/icons/arrow--next.svg");
				}
			}
		}
		.black-arrow-next {
			&:hover {
				&:after {
					background-image: url("/icons/arrow--prev.svg");
				}
			}
		}
		//searchbox
		.search-section-container .search-section .search-input,
		.mobile-search-menu .search-section .search-input {
			border-right: 0;
			border-left: 1.5px solid rgba(0, 0, 0, 0.2);
		}
		//Commen Banner
		.common-banner .banner-text-data{
			width: 100%;
		}
		.common-banner .banner-text-data .text-description{
			width: 55%;
		}
		//mainNav
		.mainnav-container .links-container .link::after {
			right: 0;
		}
		.dev-menu
			.development-menu
			.react-tabs
			.tabs-container
			.tabs-panels
			.arrows-container {
			right: unset;
			left: 0;
		}
		.dev-menu .development-menu .react-tabs .tabs-container .tabs-panels .swiper .img-container .main-logo{
			right: 1.5rem;
			left: unset;
		}
		//homePage-banner
		.banner-section-container .swiper .swiper-navigation-continer {
			left: unset;
			right: 8.5rem;
		}
		//homePage-updates
		.updates-section .updates-swiper-section {
			.swiper-navigation-section {
				border-right: 0;
				border-left: 1px solid rgba(0, 0, 0, 0.1);

				.navigation-container {
					left: 4rem;
					right: unset;

					.slide-number-container {
						flex-direction: row-reverse;
						transform: translateY(0.5rem);

						div {
							transform: rotate(180deg);
						}
					}
				}
			}
		 
			.swiper .swiper-slide {
				border-left: 1px solid rgba(0, 0, 0, 0.1);
				border-right: 0;
			}
		}
		.updates-section .updates-swiper-section .swiper-navigation-section span{
			letter-spacing: 0;
			font-size: 1.2rem;
		}
		.updates-section .updates-header span{
			letter-spacing: 0;
			font-size: 1.2rem;
		}
		//homePage-tsbtt
		.tomorrow-section {
			.border-top,
			.border-bottom {
				right: 26.1rem;
				left: unset;
			}
		}
		//project
		.masterplan-section {
			.swiper-navigation-continer {
				left: 8.5rem;
				right: unset;
			}
			.header {
				left: unset; 
				right: 0;
			}
		}
		//projectPhase
		.phase-overview {
			.overview-swiper {
				.swiper-navigation-continer {
					left: unset;
					right: 53.9%;
					transform: translateX(50%);
				}
			}
		}
		.building-type-section {
			.swiper-navigation-continer {
				.navigation-container {
					.swiper-pagination-progressbar {
						transform: rotate(180deg);
					}
				}
			}
		}

		.phase-overview {
			.overview-swiper {
				.swiper {
					.swiper-slide {
						&.swiper-slide-next {
							&.previousDirection {
								.about-card {
									.left-container {
										.card-image {
											.img-container {
												animation: 1.5s down-leave;
											}
										}
									}
									.right-container {
										.img-container {
											animation: 1.5s prev-down-enter;
										}
									}
								}
							}
							.about-card {
								.left-container {
									.card-image {
										.img-container {
											animation-timing-function: ease-in-out;
										}
									}
								}
								.right-container {
									.img-container {
										animation-timing-function: ease-in-out;
									}
								}
							}
						}
						&.swiper-slide-prev {
							&.previousDirection {
								.about-card {
									.left-container {
										.card-image {
											.img-container {
												animation: 1.5s down-leave;
											}
										}
									}
								}
							}
							.about-card {
								.left-container {
									.card-image {
										.img-container {
											animation: 1s prev-down-enter;
											animation-timing-function: ease-in-out;
										}
									}
								}
							}
						}
						&.swiper-slide-active {
							&.previousDirection {
								.about-card {
									.left-container {
										.card-image {
											.img-container {
												animation: 1s down-enter;
											}
										}
									}
									.right-container {
										.img-container {
											animation: 1s prev-down-leave;
										}
									}
								}
							}
							.about-card {
								.left-container {
									.card-image {
										.img-container {
											animation: 1s prev-down-leave;
											animation-timing-function: ease-in-out;
										}
									} 
								}
								.right-container {
									.img-container {
										animation: 1s down-enter;
										animation-timing-function: ease-in-out;
									}
								}
							}
						}
					}
				}
			}
		}
		.construction-updates-section .swiper.thumbs-swiper {
			left: unset;
			right: 8.5rem;
		}
		.construction-updates-section .play-button {
			left: 4rem;
			right: unset;
		}
		// Project-Facilities
		.facilities-section {
			.swiper-navigation-continer {
				.swiper-pagination-progressbar {
					.swiper-pagination-progressbar-fill {
						transform-origin: right top;
					}
				}
			}
		}

		// Our-People
		.our-people-section {
			.swiper-pagination-progressbar {
				transform: rotate(180deg);
			}
		}

		// 360 WORKS >> Services Section
		.services-section {
			.react-tabs {
				.tabs-panels {
					.swiper-navigation-continer {
						.swiper-pagination-progressbar {
							transform: rotate(180deg);
						}
					}
				}
			}
		}

		.testimonials-section .testimonials-card .card-data .description {
			height: 15rem;
		}

		// 360 WORKS >> Collaborations
		.collaborations-section {
			.swiper-navigation-continer {
				.swiper-pagination-progressbar {
					transform: rotate(180deg);
				}
			}
		}
		.testimonials-section {
			.progress {
				left: unset;
				right: 8rem;
			}
			.testimonials-card {
				.img-section {
					.img-container {
						transform: translateX(0);
					}
				}
			}
		}
		// Complain Form
		.complain-form {
			.cancel-btn {
				left: 3rem;
				right: unset;
			}
			.img-container {
				right: unset;
				left: 25%;
				transform: translate(-50%, -50%);
			}
		}

		// Footer
		.footer-section {
			.links-container {
				.links {
					.quick {
						ul {
							li {
								&::after {
									left: unset;
									right: 0;
								}
							}
						}
					}
				}
			}
		}
		.project-banner .banner-wrapper .banner-text-data .text-subtitle{
			letter-spacing: 0px;
			font-size: 1.4rem;
			margin-bottom: 1rem;
		}
		.phase-banner .banner-text-data .text-subtitle{
			letter-spacing: 0px;
			font-size: 1.4rem;
		}
			// business-district Page
		.building-card .card-data{
			padding: 2rem 2rem 2.5rem 0; 
		} 
		 
		.phase-overview .header{
			height: 6.5rem;
		}
		//amenity
		.amenity{
			&::after{
				left: 0;
				right: unset;
			}
		}
	.banner-section-container .banner-wrapper .swiper-navigation-continer,
	.project-banner .banner-wrapper .swiper-navigation-continer{
		justify-content: space-between ; 
		left: 9.5rem;
	} 
	}
}
