.commercial-section {
	padding: 3.75rem 0 4rem;
	.header {
		padding-inline: 1.25rem;
		
		font-size: 2.8rem;
		line-height: 1;
		margin-bottom: 2rem;
		margin-inline-start: -0.3rem;
	}
    .swiper{
        padding-inline: 1.25rem;
        .services-card{
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 1rem;
            .img-container{
                position: relative;
                // width: 90%;
                width: 95%;
                height: 13rem;
            }
            .title{
                font-size: 1.5rem;
                line-height: 1.1;
            }
            .description{
                width: 95%;
                opacity: .85;
            }
        }
    }
    .swiper-navigation-continer {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: white;
        padding-inline: 1.25rem;
        margin-top: 2rem;
        .navigation-container {
            display: none;
        }
        .arrows-container {
            display: flex;
            gap: 1.25rem;
            justify-content: flex-start;
            .commercial-arrow {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 50%;
                border: 1px solid rgba($color: #31353d, $alpha: .6);
            }
        }
    }
}
@media (min-width: 1024px) {
	.commercial-section {
		padding: 5.75rem 0 6rem;
		.header {
			font-size: 3rem;
			padding-inline: 8.5rem;
            margin-bottom: 3rem;
		}
        .swiper{
            padding-inline: 8.5rem 2rem;
            .services-card{
                gap: 1.5rem;
                .img-container{
                    width: 100%;
                }
            }
        }
        .swiper-navigation-continer {
            justify-content: space-between;
            width: 100%;
            padding-inline: 8.5rem;
            margin-top: 3rem;
            .navigation-container {
                display: flex;
                flex-direction: column;
                width: 9.7rem;
                gap: 0.25rem;
                .swiper-pagination-progressbar {
                    width: 9.7rem;
                    height: 0.2rem;
                    background: rgba(0, 0, 0, 0.2);
                    position: relative;
                    .swiper-pagination-progressbar-fill {
                        background-color: #000 !important;
                        opacity: 1;
                    }
                }
                .slide-number-container {
                    display: flex;
                    color: black;
                    display: flex;
                    justify-content: space-between;
                    font-size: 0.75rem;
                    letter-spacing: 0.05em;
                    line-height: 1rem;
                }
            }
            .arrows-container {
                justify-content: flex-end;
                .serv-arrow {
                    width: 3rem;
                    height: 3rem;
                    &:hover{
                        background-color: #31353d;
                    }
                }
            }
        }.swiper-navigation-continer {
					justify-content: space-between;
					width: 100%;
					padding-inline: 8.5rem;
                    margin-top: 4rem;
					.navigation-container {
						display: flex;
						flex-direction: column;
						width: 9.7rem;
						gap: 0.25rem;
						.swiper-pagination-progressbar {
							width: 9.7rem;
							height: 0.2rem;
							background: rgba(0, 0, 0, 0.2);
							position: relative;
							.swiper-pagination-progressbar-fill {
								background-color: #000 !important;
								opacity: 1;
							}
						}
						.slide-number-container {
							display: flex;
							color: black;
							display: flex;
							justify-content: space-between;
							font-size: 0.75rem;
							letter-spacing: 0.05em;
							line-height: 1rem;
						}
					}
					.arrows-container {
						justify-content: flex-end;
						.commercial-arrow {
							width: 3rem;
							height: 3rem;
                            &:hover{
                                background-color: #31353d;
                            }
						}
					}
				}
	}
}
