.facilities-section {
	background-repeat: no-repeat;
	background-size: cover;
	padding: 3.75rem 0rem 4rem;
	display: flex;
	flex-direction: column;
	scroll-margin-top: 2rem;
	position: relative;
	overflow: hidden;
	&::before {
		content: '';
		position: absolute;
		top: 0;
		left:0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.2);
		z-index: 0;
	}
	.bg-image{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		.overlay{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: rgba(0, 0, 0, 0.5);
			z-index: 1;
		}
	}
	.header {
		position: relative;
		color: white;
		font-size: 2rem;
		line-height: 1;
		margin-bottom: 2rem;
		padding-inline: 1.25rem;
		margin-inline-start: -0.3rem;
		z-index: 2;
	}
	.swiper-container {
		margin-bottom: 2.5rem;
		z-index: 2;
		position: relative;
		.swiper {
			padding-inline: 1.25rem;
		}
	}
	.swiper-navigation-continer {
		display: flex;
		flex-direction: row;
		align-items: center;
		color: white;
		padding-inline: 1.25rem;
		z-index: 2;
		justify-content: space-between;
		.navigation-container {
			display: flex;
			flex-direction: column;
			width: 9.7rem;
			margin-bottom: .25rem;
			// gap: 0.25rem;
			.swiper-pagination-progressbar {
				width: 9.7rem;
				height: 0.2rem;
				background: rgba(255, 255, 255, 0.2);
				position: relative;
				.swiper-pagination-progressbar-fill {
					background-color: #ffffff !important;
					opacity: 1;
				}
			}
			.slide-number-container {
				display: flex;
				color: #ffffff;
				display: flex;
				justify-content: space-between;
				font-size: 0.75rem;
				letter-spacing: 0.05em;
				line-height: 1rem;
			}
		}
		.arrows-container {
			display: flex;
			gap: .75rem;
			justify-content: flex-start;
			.facility-arrow {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 2.5rem;
				height: 2.5rem;
				border-radius: 50%;
				border: 1px solid rgba($color: #ffffff, $alpha: 1);
			}
		}
	}
}
@media (min-width: 1024px) {
	.facilities-section {
		padding: 5.75rem 8.5rem 6rem;
		.header {
			font-size: 3rem;
			margin-bottom: 3rem;
			padding-inline: unset;
		}
		.swiper-container {
			background-color: white;
			padding: 3rem 0;
			margin-bottom: 3rem;
			.swiper {
				// padding-inline: 3rem;
				padding-inline-start: 3rem;
				padding-inline-end: 1.5rem;
			}
		}
		.swiper-navigation-continer {
			justify-content: space-between;
			width: 100%;
			padding-inline: 0;
			.navigation-container {
				margin-bottom: 0;
			}
			.arrows-container {
				.facility-arrow {
					border: 1px solid rgba($color: #ffffff, $alpha: 1);
					width: 3rem;
					height: 3rem;
					&:hover {
						background: #fff;
					}
				}
			}
		}
	}
}
