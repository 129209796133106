.project-overview-section {
	display: flex;
	flex-direction: column;
	padding: 4rem 1.25rem;
	width: 100%;
	scroll-margin-top: 2rem;
	overflow: hidden;
	&.custom-padding-bottom {
		padding-bottom: 3rem;
	}
	.project-data {
		display: flex;
		flex-direction: column;
		height: 100%;
		justify-content: center;
		color: black;
		margin-bottom: 1rem;
		.text-subtitle {
			font-size: 0.6rem;
			letter-spacing: 8px;
			text-transform: uppercase;
			margin-bottom: 1.5rem;
			opacity: 0.8;
		}
		.text-title {
			font-size: 2rem;
			line-height: 1;
			margin-bottom: 1.5rem;
			// margin-inline-start: -0.3rem;
		}
		.text-description {
			font-size: 1rem;
			
			width: 95%;
			opacity: 0.9;
		}
	}
	.project-details {
		display: flex;
		flex-direction: column;
		scroll-margin-top: 2rem;
		.icon-container {
			display: flex;
			align-items: center;
			gap: 1.5rem;
			font-size: 1.25rem;
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			padding: 1.5rem 0;
			span{
				font-size: 1rem;
			}
			&:last-child {
				border: 0;
			}
			.img-container {
				position: relative;
				height: 1.5rem;
				width: 1.5rem;
			}
		}
	}
}
@media (min-width: 1024px) {
	.project-overview-section {
		flex-direction: row;
		padding: 5.75rem 8.5rem 6rem;
		&.custom-padding-bottom {
			padding-bottom: 4rem;
		}
		.project-data {
			margin-bottom: unset;
			width: 50%;
			.text-description {
				width: 75%;
			}
			.text-title{
				font-size: 4rem;
			}
			.text-subtitle{
				font-size: .75rem;
			}
		}
		.project-details {
			width: 50%;
			padding-inline-start: 10rem;
			.icon-container {
				width: 100%;
				gap: 2rem;
				span{
					font-size: 1.2rem;
				}
				&:last-child {
					border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				}
			}
		}
	}
}
