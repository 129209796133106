.subnav-container {
	width: 100%;
	background: #31353d;
	opacity: 0.9;
	height: 2.85rem;
	padding-inline-start: 1.25rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 10;
	.lang-switcher {
		color: white;
		cursor: pointer;
	}
	.contact-container {
		display: flex;
		flex-direction: row;
		height: 100%;
		.phone {
			cursor: pointer;
			display: flex;
			color: #ffff;
			height: 100%;
			align-items: center;
			padding-inline: 1.5rem;
			background-color: #292c33;
			margin-inline-end: 0.1rem;
			.icon-img {
				margin-inline-end: 0.5rem;
				width: 1rem;
				height: 1rem;
				position: relative;
			}
			span {
				font-size: 1rem;
			}
		}
		.search {
			cursor: pointer;
			display: flex;
			color: #ffff;
			height: 100%;
			align-items: center;
			background-color: #292c33;
			padding-inline: 1.5rem;
			.icon-img {
				width: 1rem;
				height: 1rem;
				position: relative;
			}
			span {
				font-size: 1rem;

				
			}
			&.booknow {
				background: rgba(255, 255, 255, 0.9);
				color: black;
				opacity: 1;
				text-transform: capitalize;
			}
			&.icon{
				padding-inline: 0;
				width: 4.25rem;
				justify-content: center;
			}
		}
	}
}
@media (min-width: 1024px) {
	.subnav-container {
		padding-inline-start: 2.5rem;
		transition: .5s all;
		.contact-container {
			display: flex;
			flex-direction: row;
			height: 100%;
			.phone {
				margin-inline-end: 2rem;
				padding-inline: unset;
				background-color: unset;
				.icon-img {
					margin-inline-end: 0.5rem;
				}
			}
			.search {
				padding-inline: 2.25rem;
				.icon-img {
					margin-inline-end: 0.5rem;
				}
			}
		}
	}
}
