.updates-section {
	padding: 5.75rem 0rem 0rem;
	&.article{
		background-color: #f7f7f7f7;
		padding-top: 3.75rem;
	}
	.updates-header {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		color: #31353d;
		margin-bottom: 2.5rem;
		padding-inline: 8.5rem;
		overflow: hidden;
		span {
			font-size: 0.7rem;
			letter-spacing: 0.8rem;
		}
		.main-header {
			display: flex;
			justify-content: space-between;
			h3 {
				font-size: 3rem;
				line-height: 1;
				margin-inline-start: -0.3rem;
			}
			.see-updates {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 0.5rem;
				cursor: pointer;
				.circle {
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					position: relative;
					&::before {
						content: "";
						position: absolute;
						width: 0.85rem;
						height: 0.85rem;
						border-radius: 50%;
						background: rgba($color: #000000, $alpha: 0.1);
						border: 1px solid rgba($color: #212c55, $alpha: 0.05);
					}
				}
				span {
					color: #31353d;
					opacity: 0.85;
					line-height: 1;
					
					font-size: 1.25rem;
					letter-spacing: 0;
				}
			}
		}
	}
	.updates-swiper-section {
		display: flex;
		width: 100%;
		padding: 1rem 0 0;
		padding-inline-start: 8.5rem;
		border-top: 1px solid rgba(0, 0, 0, 0.1);
		.swiper-navigation-section {
			padding: 1.5rem 0 4rem 0;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			border-right: 1px solid rgba(0, 0, 0, 0.1);
			gap: 12rem;
			// gap: 14rem;
			width: 17.65rem;
			span {
				letter-spacing: 6px;
				line-height: 2;
				width: 80%;
				text-transform: uppercase;
				font-size: 0.7rem;
			}
			.navigation-container {
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				width: 9.7rem;
				gap: 0.25rem;
				position: relative;
				transform: rotate(90deg);
				right: 5rem;
				progress {
					width: 9.7rem;
					height: 0.2rem;
					position: absolute;
					top: 0;
					&::-webkit-progress-bar {
						background: rgba($color: #000000, $alpha: 0.2) !important;
						transition: 0.25s all;
					}
					&::-moz-progress-bar {
						background:  #000 !important;
						transition: 0.25s all;
					}
					&::-webkit-progress-value {
						background: #000000 !important;
						transition: 0.25s all;
					}
					&::-moz-progress-value {
						background: #000000 !important;
						transition: 0.25s all;
					}
				}
				.swiper-pagination-progressbar {
					width: 9.7rem;
					height: 0.2rem;
					background: rgba($color: #000000, $alpha: 0.2) !important;
					.swiper-pagination-progressbar-fill {
						background: #000000 !important;
					}
				}
				.slide-number-container {
					display: flex;
					color: black;
					flex-direction: row;
					justify-content: space-between;
					font-size: 0.85rem;
					letter-spacing: 0.05em;
					line-height: 1rem;
					z-index: 2;
					width: 100%;
					transform: translateY(-1.25rem);
				}
			}
			.arrows-container {
				display: flex;
				gap: 1.25rem;
				justify-content: flex-start;
				.updates-arrow {
					cursor: pointer;
					display: flex;
					justify-content: center;
					align-items: center;
					width: 3rem;
					height: 3rem;
					border-radius: 50%;
					border: 1px solid rgba($color: #31353d, $alpha: 0.5);
					&:hover {
						background-color: #31353d;
					}
				}
			}
		}
		.swiper {
			width: calc(100% - 17.65rem);
			.swiper-slide {
				border-right: 1px solid rgba(0, 0, 0, 0.1);
				padding-inline: 2rem;
				display: flex;
				justify-content: center;
				align-items: center;
				.update-card {
					width: 22rem;
					height: 100%;
					padding-top: 2rem;
					padding-bottom: 4rem;
					position: relative;
					&.hoverd {
						.img-conatiner {
							height: 60%;
							display: flex;
							justify-content: center;
							align-items: center;

							.date {
								position: absolute;
								bottom: 1.5rem;
								z-index: 1;
								color: white;
								left: 1.5rem;
								font-size: 0.9rem;
							}
							.circle {
								// height: 2rem;
								color: #fff;
								position: absolute;
								z-index: 1;
								// background-color: white;
								// border-radius: 50%;
								display: flex;
								justify-content: center;
								align-items: center;
								transition: 0.5s all;
								position: relative;
								cursor: pointer;
							}
						}
					}
					.img-conatiner {
						width: 100%;
						height: 100%;
						position: relative;
						transition: 0.75s all;
						.overlay {
							position: absolute;
							top: 0;
							width: 100%;
							height: 100%;
							z-index: 1;
							background-color: rgba(0, 0, 0, 0.4);
						}
						.tag {
							position: absolute;
							top: 1.5rem;
							z-index: 1;
							color: white;
							left: 1.5rem;
							font-size: 0.75rem;
							text-transform: uppercase;
							&.tag-title {
								top: unset;
								bottom: 1.5rem;
								
								font-size: 1rem;
							}
						}
						.read-more-container {
							position: absolute;
							margin: auto;
							color: white;
							z-index: 2;
						}
					}
					.slide-data {
						display: flex;
						flex-direction: column;
						margin-top: 1rem;
						gap: 1rem;
						height: 100%;
						span {
							font-size: 1.3rem;
							width: 73%;
							color: #000000;
							
							display: -webkit-box;
							-webkit-line-clamp: 2;
							-webkit-box-orient: vertical;
							overflow: hidden;
							&.title {
								height: 3.75rem;
								width: 95%;
							}
							&.link {
								color: rgba(0, 0, 0, 0.7);
								transition: 0.3s all;
								width: fit-content;
								&:hover {
									color: black;
								}
							}
						}

						p {
							color: #000000;
							opacity: 0.6;
							display: -webkit-box;
							-webkit-line-clamp: 4;
							-webkit-box-orient: vertical;
							overflow: hidden;
						}
						.link {
							font-size: 1rem;
							cursor: pointer;
						}
					}
				}
			}
		}
	}
}
