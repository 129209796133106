.footer-section {
	background-color: #31353d;
	overflow: hidden;
	.logo-container {
		display: flex;
		flex-direction: column;
		padding: 4rem 1.25rem .5rem;
		gap: 2rem;
		border-bottom: 1px solid rgba($color: #f0eeed, $alpha: 0.1);
		.img {
			width: 10rem;
			height: 2rem;
			position: relative;
			cursor: pointer;
		}
		.form {
			// transition: 0.5s all;
			&.form-error {
				input {
					border: 1px solid red;
					transition: 0.5s all;
				}
			}
			span {
				font-size: 1rem;
				color: #f0eeed;
			}
			.input-group {
				width: 100%;
				position: relative;
				display: flex;
				flex-direction: column;
				.input-error {
					position: absolute;
					bottom: -1.25rem;
					color: red;
					font-size: .75rem;
				}
			}
			input {
				transition: 0.5s all;
				width: 100%;
				height: 4rem;
				background: rgba($color: white, $alpha: 0.06);
				margin-top: 1.25rem;
				padding-inline-start: 1.5rem;
				border-radius: 0;
				color: rgba($color: white, $alpha: 1);
				&::placeholder {
					color: #f0eeed;
					opacity: 0.4;
					font-size: .8rem;
				}
				&:focus {
					outline: none;
				}
			}
		}
		p{
			color: #fff;
			font-size: .85rem;
		}
	}
	.links-container {
		display: none;
		flex-direction: column;
		h6 {
			line-height: 1;
			font-size: 1rem;
			color: #f0eeed;
			opacity: 0.4 !important;
			margin-bottom: 1.5rem;
		}
		.links {
			padding: 4rem 1.25rem;
			width: 100%;
			justify-content: space-between;
			display: flex;
			flex-wrap: wrap;
			border-bottom: 1px solid rgba($color: #f0eeed, $alpha: 0.1);
			// overflow: hidden;
			.quick {
				// overflow: hidden;
				&:last-child {
					padding-top: 3rem;
				}
				ul {
					list-style: none;
					font-size: 1.4rem;
					color: rgba($color: #f0eeed, $alpha: 0.85);
					li {
						margin-bottom: 0.8rem;
						// overflow: hidden;
						&:last-child {
							margin-bottom: 0;
						}
					}
				}
			}
		}
		.social {
			padding: 4rem 1.25rem;
			display: flex;
			flex-direction: column;
			gap: 2rem;
			border-bottom: 1px solid rgba($color: #f0eeed, $alpha: 0.1);
		}
		.emails {
			display: flex;
			flex-direction: column;
			gap: 2rem;
			.email-container {
				h6 {
					font-size: 1.25rem;
					color: rgba($color: #f0eeed, $alpha: 0.85);

					opacity: 1;
					margin-bottom: 0.5rem;
				}
				.text {
					color: white;
					opacity: 0.5;
				}
			}
		}
		.icons-container {
			h6 {
				font-size: 1.125rem;
			}
			.icons {
				display: flex;
				gap: 1.5rem;
				a span {
					display: none;
				}
			}
		}
	}
	.copy-rights {
		padding: .5rem 1.25rem 1rem;
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		color: rgba($color: white, $alpha: 0.5);
		font-size: 0.85rem;
		a {
			color: white;
		}
	}
	.button-section {
		padding: 1.375rem 1.5rem;
		background-color: white;
		color: #31353d;
		margin-top: 2rem;
		font-weight: 800;
		text-transform: uppercase;
		width: 100%;
		&.submitted {
			opacity: 0.8;
		}
		span {
			font-size: 0.9rem !important;
		}
	}
}
@media (min-width: 1024px) {
	.footer-section {
		.logo-container {
			flex-direction: row;
			padding: 4rem 8.5rem;
			width: 100%;
			justify-content: space-between;
			.img {
				width: 16.75rem;
				height: 3.25rem;
			}
			.form {
				border: 1px solid #31353d;
				background: rgba($color: white, $alpha: 0.06);
				display: flex;
				gap: 4rem;
				padding: 1.25rem 1rem;
				&.form-error {
					input {
						border: 0;
					}
					border: 1px solid red;
					transition: 0.5s all;
				}
				span {
					font-size: 1rem;
				}
				.input-group {
					display: flex;
					flex-direction: column;
					gap: 0.5rem;
					.input-error {
						bottom: -3rem;
						font-size: .85rem;
					}
				}
				input {
					background-color: unset;
					margin: 0;
					height: unset;
					padding: unset;
				}
				.button-section {
					margin-top: 0.5rem;
					padding: 0;
					height: 3rem;
					width: 7rem;
					span {
						width: 100%;
						height: 100%;
						div {
							width: 100%;
							height: 100%;
							display: flex;
							justify-content: center;
							align-items: center;
						}
						font-size: 0.75rem !important;
					}
				}
			}
		}
		.links-container {
			display: flex;
			padding: 4rem 8.5rem;
			flex-direction: row;
			gap: 0;
			justify-content: space-between;
			border-bottom: 1px solid rgba($color: #f0eeed, $alpha: 0.1);
			.links {
				padding: 0;
				border-bottom: 0;
				width: 100%;
				justify-content: space-between;
				.quick {
					&:first-child {
						padding-inline-end: 0rem;
					}
					&:last-child {
						padding-top: 0rem;
					}

					ul {
						font-size: 1.1rem;
						li {
							margin-bottom: 0.8rem;

							cursor: pointer;
							&:last-child {
								margin-bottom: 0;
							}
							// transition: all ease-in-out 0.25s;
							position: relative;
							width: fit-content;
							&::after {
								position: absolute;
								content: "";
								width: 0;
								height: 2px;
								left: 0;
								bottom: -0.2rem;
								background-color: #fff;
								// transition: all ease-in-out 0.3s;
							}
							&.active {
								color: #fff;
								// &::after {
								// 	width: 100%;
								// }
							}
						}
					}
				}
			}
			.border {
				width: 1px;
				background-color: rgba(255, 255, 255, 0.1);
			}
			.emails {
				padding-top: 2.75rem;
				.email-container {
					h6 {
						font-size: 1.1rem;
					}
				}
			}
			.icons-container {
				h6 {
					font-size: 1rem;
					margin-bottom: 2rem;
				}
				// .icons {
				// 	svg {
				// 		transition: 0.25s all;
				// 		&:hover {
				// 			transform: scale(1.15);
				// 		}
				// 	}
				// }
			}
		}
		.copy-rights {
			padding: 1rem 8.5rem;
			padding-inline: 8.5rem;
			flex-direction: row;
			justify-content: space-between;
			p {
				&:last-child {
					font-size: 0.85rem;
				}
			}
		}
	}
}
