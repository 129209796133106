.landmarks-section {
    padding: 3.75rem 0 4rem;
    scroll-margin-top: 2rem;
    overflow: hidden;
    .header {
        margin-bottom: 2rem;
        padding-inline-start: 1.25rem;
        h2 {
            font-size: 2rem;
            line-height: 1.1;
            margin-bottom: 1rem;
            margin-inline-start: -0.3rem;
        }
        p {
            width: 95%;
        }
    }
    .map-container {
        width: 100%;
        height: 19rem;
        position: relative;
    }
}

@media (min-width: 1024px) {
    .landmarks-section {
        padding: 5.75rem  0 6rem;
        .header {
            margin-bottom: 3rem;
            padding-inline: 8.5rem;
            h2 {
                font-size: 3rem;
                margin-bottom: 2rem;
            }
            p {
                font-size: 1rem;
                width: 50%;
            }
        }

        .map-container {
            height: 27rem;
        }
    } 
}