.people-card {
    display: flex;
    flex-direction: column;
    .img-container {
        height: 26rem;
        position: relative;
    }
    .details {
        padding-top: 0.6rem;
        .name {
            font-size: 1.75rem;
        }
        .title {
            font-size: 1rem;
            
        }
    }
}