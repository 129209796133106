.similar-projects-section {
	padding: 3.75rem 0 4rem;
	background-color: #f6f6f6;
	scroll-margin-top: 2rem;
	overflow: hidden;
	&.similar-phases {
		.project-details-mobile {
			.specs {
				min-height: 5rem !important;
			}
		}
	}
	.header {
		margin-bottom: 2rem;
		padding-inline-start: 1.25rem;

		h2 {
			font-size: 2rem;
			line-height: 1.1;
			margin-bottom: 1rem;
		}
	}

	.projects-container {
		.swiper {
			padding-inline-start: 1.25rem;
			padding-inline-end: 1rem;
			.swiper-wrapper {
				.swiper-slide {
					.project {
						background-color: #fff;
						display: grid;
						grid-template-columns: 1fr;
						gap: 1rem;
						.img-container {
							height: 11.25rem;
							position: relative;
							.overlay {
								position: absolute;
								top: 0;
								width: 100%;
								height: 100%;
								background-color: rgba($color: #000000, $alpha: 0.3);
								z-index: 1;
							}
						}

						.project-details-mobile {
							display: flex;
							flex-direction: column;
							justify-content: center;
							padding: 0 1rem 1.5rem;
							position: relative;
							z-index: 2;
							.title {
								font-size: 1.25rem;
								position: absolute;
								top: -4rem;
								color: #fff;
							}

							.specs {
								display: flex;
								flex-direction: column;
								min-height: 11.25rem;
								.spec {
									display: flex;
									align-items: center;
									font-size: 0.9rem;
									position: relative;
									padding: 1.1rem 0;
									width: 100%;

									&:not(:last-child) {
										border-bottom: solid 1px rgba($color: #000000, $alpha: 0.1);
									}

									.icon-container {
										position: relative;
										width: 1.1rem;
										height: 1.1rem;
										margin-inline-end: 1.25rem;
									}
								}
							}
							.buttons-container {
								display: flex;
								flex-direction: column;
								align-items: center;
								gap: 1rem;
								margin-top: 0.5rem;
								div{
									width: 100%;
								}
								.button-section {
									width: 100%;
									padding-inline: 0;
								}
							}
						}
					}
				}
			}
			.swiper-navigation-continer {
				display: flex;
				flex-direction: row;
				align-items: center;
				color: white;
				margin-top: 2.5rem;
				justify-content: space-between;
				.navigation-container {
					display: flex;
					flex-direction: column;
					width: 9.7rem;
					gap: 0.25rem;
					margin-bottom: .25rem;
					.swiper-pagination-progressbar {
						width: 9.7rem;
						height: 0.2rem;
						background: rgba(0, 0, 0, 0.2);
						position: relative;
						.swiper-pagination-progressbar-fill {
							background-color: #000 !important;
							opacity: 1;
						}
					}
					.slide-number-container {
						display: flex;
						color: black;
						display: flex;
						justify-content: space-between;
						font-size: 0.75rem;
						letter-spacing: 0.05em;
						line-height: 1rem;
					}
				}
				.arrows-container {
					display: flex;
					gap: .75rem;
					justify-content: flex-start;
					.similar-arrow {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 2.5rem;
						height: 2.5rem;
						border-radius: 50%;
						border: 1px solid rgba($color: #31353d, $alpha: 0.6);
					}
				}
			}
		}
	}
}

@media (min-width: 1024px) {
	.similar-projects-section {
		padding: 5.75rem 0 6rem;
		position: relative;
		z-index: 1;
		.header {
			margin-bottom: 3rem;
			padding-inline: 8.5rem;
			display: flex;
			align-items: center;
			justify-content: space-between;

			h2 {
				font-size: 3rem;
				margin-bottom: 0;
			}

			.see-all {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 0.5rem;
				cursor: pointer;

				.circle {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 2rem;
					height: 2rem;
					border-radius: 50%;
					background: rgba($color: #000000, $alpha: 0.05);
					position: relative;
					border: 1px solid rgba($color: #212c55, $alpha: 0.05);

					&::before {
						content: "";
						position: absolute;
						width: 0.85rem;
						height: 0.85rem;
						border-radius: 50%;
						background: rgba($color: #000000, $alpha: 0.1);
						border: 1px solid rgba($color: #212c55, $alpha: 0.05);
					}

					&::after {
						content: "";
						position: absolute;
						width: 0.25rem;
						height: 0.25rem;
						border-radius: 50%;
						background: rgba($color: #000000, $alpha: 0.7);
					}
				}

				span {
					color: #31353d;
					line-height: 1;

					font-size: 1.25rem;
					letter-spacing: 0;
				}
			}
		}

		.projects-container {
			.swiper {
				padding-inline-start: 8.5rem;
				padding-inline-end: 3rem;
				.swiper-wrapper {
					.swiper-slide {
						.project {
							gap: 2rem;
							padding: 1rem;
							height: 28.5rem;
							.img-container {
								height: 100%;
								.project-details {
									display: flex;
									justify-content: flex-end;
									flex-direction: column;
									padding: 0 2rem 2.5rem;
									position: absolute;
									color: #fff;
									z-index: 2;
									width: 100%;
									height: 100%;
									transition: 0.25s all;
									gap: 1rem;
									.title {
										font-size: 1.25rem;
									}
									.specs {
										display: flex;
										min-height: unset;
										width: 100%;
										gap: 1.25rem;
										.spec {
											display: flex;
											align-items: center;
											position: relative;
											padding: 0;
											border: none;
											font-size: 1rem;
											width: unset;
											.icon-container {
												position: relative;
												width: 1.1rem;
												height: 1.1rem;
												margin-inline-end: .75rem;
												img {
													filter: invert(100%) sepia(0%) saturate(7493%)
														hue-rotate(245deg) brightness(99%) contrast(96%);
												}
											}
										}
									}
									.buttons-container {
										display: flex;
										align-items: center;
										gap: 1rem;
										margin-top: 2rem;
										opacity: 0;
										transition: 0.25s all;
										position: absolute;
										top: 50%;
										left: 50%;
										transform: translate(-50%, -50%);
										.button-section {
											cursor: none;
											width: 10rem;
											padding-inline: 0;
											&.white-button {
												background-color: white;
												border: 1px solid white;
												&:hover {
													border: 1px solid #31353d;
													background-color: transparent;
												}
											}
										}
									}
								}
								.hoverd {
									position: absolute;
									width: 100%;
									height: 100%;
									z-index: 2;
									.hoverd-items {
										position: absolute;
										transition: 0.25s all;
										background: #ffff;
										&.left {
											left: 0;
											height: 100%;
											width: 0rem;
										}
										&.right {
											right: 0rem;
											height: 100%;
											width: 0;
										}
										&.top {
											top: 0rem;
											width: 100%;
											height: 0;
										}
										&.bottom {
											bottom: 0rem;
											width: 100%;
											height: 0;
										}
									}
								}
								&:hover {
									.hoverd-items {
										transition: 0.25s all;
										&.left,
										&.right {
											width: 1rem;
										}
										&.top,
										&.bottom {
											height: 1rem;
										}
									}
									.project-details {
										.title {
											opacity: 0;
										}
										.specs {
											opacity: 0;
										}
										.buttons-container {
											display: flex;
											margin-top: 0;
											opacity: 1;
											.button-section {
												cursor: pointer;
											}
										}
									}
								}
							}
						}
					}
				}
				.swiper-navigation-continer {
					justify-content: space-between;
					width: 100%;
					padding-inline: 0 5.5rem;
					margin-top: 3rem;
					margin-bottom: 2rem;
					.navigation-container {
						display: flex;
						flex-direction: column;
						width: 9.7rem;
						gap: 0.25rem;
						margin-bottom: 0;
						.swiper-pagination-progressbar {
							width: 9.7rem;
							height: 0.2rem;
							background: rgba(0, 0, 0, 0.2);
							position: relative;
							.swiper-pagination-progressbar-fill {
								background-color: #000 !important;
								opacity: 1;
							}
						}
						.slide-number-container {
							display: flex;
							color: black;
							display: flex;
							justify-content: space-between;
							font-size: 0.75rem;
							letter-spacing: 0.05em;
							line-height: 1rem;
						}
					}
					.arrows-container {
						justify-content: flex-end;
						.similar-arrow {
							width: 3rem;
							height: 3rem;
							&:hover {
								background-color: #31353d;
							}
						}
					}
				}
			}
		}
	}
}
