.common-banner {
	display: flex;
	width: 100%;
	height: 50rem;
	flex-direction: column;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	padding-inline: 1.25rem;
	padding-top: 6.85rem;
	align-items: center;
	justify-content: center;
	z-index: 1;
	overflow: hidden;
	.banner-image-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		background-color: rgba(0, 0, 0, 0.5);
	}
	.banner-text-data {
		display: flex;
		flex-direction: column;
		color: #ffffff;
		position: relative;
		z-index: 3;
		h1 {
			font-size: 2rem;
			margin-bottom: 1.5rem;
			line-height: 1.2;
		}
		.text-description {
			font-size: 1rem;
			width: 95%;
			opacity: 0.8;
		}
	}
}
@media (min-width: 1024px) {
	.common-banner {
		height: 100vh;
		padding-inline: 8.5rem;
		flex-direction: row;
		padding-top: 10rem;
		// padding-top: 10.45rem;
		justify-content: flex-start;
		.banner-text-data {
			h1 {
				font-size: 4rem;
				line-height: 1.25;
				overflow: hidden;
			}
			.text-description {
				width: 65%;
				line-height: 1.75;
			}
			.buttons-group {
				.line {
					margin-inline-end: 1.5rem;
				}
			}
		}
	}
}
