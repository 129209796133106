.search-section-container-mobile {
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 2.5rem 1.25rem;
	z-index: 1;
	width: 100%;
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	.search-section {
		width: 100%;
		background-color: #ffff;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		.search-input {
			display: none;
		}
		.search-button {
			display: flex;
			justify-content: center;
			align-items: center;
			position: relative;
			z-index: 1;
			width: 50%;
			.button-section {
				min-width: 80%;
			}
		}
	}
}
@media (min-width: 768px) {
	.search-section-container .search-section {
		width: 70%;
	}
}
@media (min-width: 1024px) {
	.search-home {
		position: absolute;
		bottom: 2.25rem;
		z-index: 1;
		width: 100%;
	}
	.search-section-container {
		display: flex;
		justify-content: center;
		align-items: center;
		padding-inline: 8.5rem;
		width: 100%;
		.search-section {
			width: 100%;
			background-color: #ffff;
			height: 6.15rem;
			display: grid;
			grid-template-columns: 1fr 1fr 1fr 0.725fr;
			// grid-template-columns: 1fr 1fr 1fr 1fr 0.725fr;
			align-items: center;
			.search-input {
				display: flex;
				flex-direction: column;
				justify-content: center;
				height: 80%;
				border-right: 1.5px solid rgba(0, 0, 0, 0.2);
				color: black;
				position: relative;
				span {
					position: absolute;
					bottom: 0rem;
					padding-inline-start: 1rem;
					color: red;
				}
				.css-tj5bde-Svg {
					fill: black;
				}
				label {
					text-transform: uppercase;
					color: #000000;
					opacity: 0.3;
					font-size: 0.85rem;
					margin-bottom: -0.5rem;
					padding-inline-start: 1rem;
				}
			}
			.search-button {
				width: 100%;
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				.button-section {
					&.black-button {
						min-width: 10rem;
					}
				}
				.button-container {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 11.2rem;
					height: 3.15rem;
					border-radius: 6px;
					background-color: #31353d;
					color: #ffff;
					font-size: 0.75rem;
					text-transform: uppercase;
					letter-spacing: 0.05em;
				}
			}
		}
	}
}
