.mobile-menu {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: #31353d;
	height: 100%;
	z-index: 5;
	display: flex;
	padding-top: 7rem;
	// align-items: center;
	width: 100vw;
	.bg{
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		.img-container{
			width: 100%;
			height: 100%;
			position: relative;
		}
}
	.links-container {
		display: flex;
		flex-direction: column;
		align-items: center;
		overflow: auto;
		width: 100%;
		position: relative;
		z-index: 1;
		padding-top: 3rem;
		.link {
			width: calc(100% - 2.5rem);
			color: #fff;
			padding: 1rem 0;
			// border-bottom: 1px solid #fff;
			font-size: 1.25rem;
			&.active {
				font-size: 1.5rem;
			}
			&.active-dev {
				font-size: 1.5rem;
			}
			&:last-child {
				border: 0;
			}
			&.development {
				.chakra-accordion {
					button {
						text-align: start;
						display: flex;
						align-items: center;
						.button-name {
							&.active {
								font-size: 1.5rem;
								// color: black;
							}
						}
						.chakra-icon {
							width: 1.25rem;
							height: 1.25rem;
							margin-inline-start: 0.5rem;
							margin-top: 0.15rem;
							transition: 0.2s all;
							&.active {
								// color: black;
							}
						}
						&[aria-expanded="true"] {
							// color: black !important;
							.chakra-icon {
								transform: rotate(-180deg);
							}
						}
					}
					.chakra-collapse {
						.button-name {
							padding-top: 1rem;
							font-size: 1.15rem;
							width: 50%;
						}
						.chakra-icon {
							margin-top: 1.15rem;
						}
					}
					.chakra-accordion__panel {
						ul {
							margin-bottom: 1rem;
							list-style: none;
							li {
								padding-top: 1rem;
								font-size: 1rem;
								&.active {
									// color: black;
									font-size: 1.2rem;
								}
							}
						}
					}
				}
			}
		}
	}
}
@media (orientation: landscape) {
	.mobile-menu {
		padding-top: 8rem;
	}
	.mobile-menu
		.links-container
		.link.development
		.chakra-accordion
		.chakra-collapse
		.button-name {
		width: 30%;
	}
}
