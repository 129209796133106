.building-type-section{
    scroll-margin-top: 2rem;
    background: #F7F7F7;
    display: flex;
    flex-direction: column;
    padding: 4rem 0rem;
    overflow: hidden;
    .header {
        margin-bottom: 2rem;
        padding-inline-start: 1.25rem;
        h2 {
            font-size: 2rem;
            line-height: 1.1;
        }
    }
    .swiper{
        padding-inline: 1.25rem;
        width: 100%;
    }
    .swiper-navigation-continer {
        display: flex;
        flex-direction: row;
        align-items: center;
        color: white;
        padding-inline: 1.25rem;
        margin-top: 2.5rem;
        justify-content: space-between;
        .navigation-container {
            display: flex;
            flex-direction: column;
            width: 9.7rem;
            // gap: 0.25rem;
            margin-bottom: .25rem;
            .swiper-pagination-progressbar {
                width: 9.7rem;
                height: 0.2rem;
                background: rgba(0, 0, 0, 0.2);
                position: relative;
                .swiper-pagination-progressbar-fill {
                    background-color: #000 !important;
                    opacity: 1;
                }
            }
            .slide-number-container {
                display: flex;
                color: black;
                display: flex;
                justify-content: space-between;
                font-size: 0.75rem;
                letter-spacing: 0.05em;
                line-height: 1rem;
            }
        }
        .arrows-container {
            display: flex;
            gap: .75rem;
            justify-content: flex-start;
            .building-arrow {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 2.5rem;
                height: 2.5rem;
                border-radius: 50%;
                border: 1px solid rgba($color: #31353d, $alpha: .6);
            }
        }
    }

}
@media(min-width:1024px){
    .building-type-section{
        padding: 5.75rem 0 6remrem;
        .header {
            margin-bottom: 3rem;
            padding-inline:  8.5rem;

            h2 {
                font-size: 3rem;
            }
        }
        .swiper{
            padding-inline: 8.5rem 5rem;
        }
        .swiper-navigation-continer {
            justify-content: space-between;
            width: 100%;
            padding-inline: 8.5rem;
            margin-top: 3rem;
            .navigation-container {
                display: flex;
                flex-direction: column;
                width: 9.7rem;
                margin-bottom: 0;
                // gap: 0.25rem;
                .swiper-pagination-progressbar {
                    width: 9.7rem;
                    height: 0.2rem;
                    background: rgba(0, 0, 0, 0.2);
                    position: relative;
                    .swiper-pagination-progressbar-fill {
                        background-color: #000 !important;
                        opacity: 1;
                    }
                }
                .slide-number-container {
                    display: flex;
                    color: black;
                    display: flex;
                    justify-content: space-between;
                    font-size: 0.75rem;
                    letter-spacing: 0.05em;
                    line-height: 1rem;
                }
            }
            .arrows-container {
                justify-content: flex-end;
                .building-arrow {
                    width: 3rem;
                    height: 3rem;
                    &:hover {
                        background-color: #31353d;
                    }
                }
            }
        }
    }
}