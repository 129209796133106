.our-people-section {
    background-color: #31353D;
    color: #fff;
    padding: 4rem 0 6rem 0;
    position: relative;
    .header {
        margin-bottom: 2rem;
        padding-inline-start: 1.25rem;
        h2 {
            font-size: 2.8rem;
            line-height: 1.1;
        }
    }
    .swiper {
        padding-inline-start: 1.25rem;
        padding-inline-end: .75rem;
    }
    .swiper-navigation-container {
        padding-inline-start: 1.25rem;
        .arrows-container {
            display: flex;
            gap: 1.25rem;
            justify-content: flex-start;
            position: relative;
            top: 2rem;
            .our-people-arrow {
              display: flex;
              justify-content: center;
              align-items: center;
              width: 2.5rem;
              height: 2.5rem;
              border-radius: 50%;
              border: 1px solid rgba($color: #ffffff, $alpha: 1);
            }
        }
        .navigation-container {
            display: none;
        }
    }
}

@media (min-width: 1024px) {
    .our-people-section {
        padding: 5.75rem 0 6rem;
        .header {
            padding-inline-start: 8.5rem;
            margin-bottom: 3rem;
        }
        .swiper {
        padding-inline-start: 8.5rem;
        }
        .swiper-navigation-container {
            width: 100%;
            padding-inline: 8.5rem;
            margin-top: 3rem;
            display: flex;
            align-items: center;
            justify-content: space-between;
            .navigation-container {
                display: flex;
                flex-direction: column;
                gap: 0.25rem;
                .swiper-pagination-progressbar {
                  width: 9.7rem;
                  height: 0.2rem;
                  background: rgba(255, 255, 255, 0.2);
                  position: relative;
                  .swiper-pagination-progressbar-fill {
                    background-color: #ffffff !important;
                    opacity: 1;
                  }
                }
                .slide-number-container {
                  display: flex;
                  color: #ffffff;
                  display: flex;
                  justify-content: space-between;
                  font-size: 0.75rem;
                  letter-spacing: 0.05em;
                  line-height: 1rem;
                }
              }
              .arrows-container {
                top: 0;
              }
        }
    }
}