.about-card {
	display: flex;
	position: absolute;
	padding-inline: 1.25rem;
	top: 0;
	z-index: 0;
	width: 100%;
	.left-container {
		display: flex;
		flex-direction: column;
		.card-image {
			height: 17rem;
			position: relative;
			.img-container {
				position: relative;
				width: 92.5%;
				height: 100%;
			}
			.small-img-container {
				position: absolute;
				width: 8.5rem;
				height: 7.5rem;
				bottom: -1rem;
				right: 0;
			}
		}
		span {
			font-size: 0.6rem;
			letter-spacing: 8px;
			margin-bottom: 1.25rem;
			opacity: 0.8;
			width: fit-content;
			overflow: hidden;
		}
		.card-data {
			display: flex;
			flex-direction: column;
			margin-top: 2.5rem;
			opacity: 0;
			min-height: 13.25rem;
			h3 {
				font-size: 1.5rem;
				line-height: 1.1;
				margin-bottom: 1.25rem;
				// min-height: 4.25rem;
			}
			p {
				// min-height: 9rem;
				font-size: .95rem;
			}
		}
	}
	.right-container {
		display: none;
	}
	.img-container,
	.small-img-container {
		transition: 0.75s all;
		clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
		overflow: hidden;
		img {
			transition: 0.75s all;
		}
	}
	&.active {
		position: relative;
		z-index: 1;
		.img-container,
		.small-img-container {
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
			img {
				transform: scale(1.05);
			}
		}
		.left-container {
			.card-data {
				opacity: 1;
			}
		}
	}
	&.prev {
		.img-container,
		.small-img-container {
			clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
		}
	}
}

@media (min-width: 1024px) {
	.about-card {
		justify-content: space-between;
		padding-inline: 8.5rem 0;
		.left-container {
			width: 35%;
			position: relative;
			z-index: 50;
			span{
				font-size: .75rem;
			}
			.card-image {
				width: 100%;
				height: 22rem;
				.img-container {
					width: 100%;
				}
				.small-img-container {
					display: none;
				}
			}
			.card-data {
				margin-top: 2rem;
				min-height: unset;
				h3{
					font-size: 2.25rem;
					min-height: unset;
				}
				p {
					min-height: unset;
					font-size: 1rem;
				}
			}
		}
		.right-container {
			width: 55%;
			display: flex;
			margin-top: 5rem;
			.img-container {
				position: relative;
				width: 100%;
				height: 32rem;
			}
		}
	}
}
