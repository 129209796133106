.masterplan-main-card {
	background-color: #31353d;
	color: #fff;
	display: grid;
	grid-template-columns: 1fr;
	gap: .5rem;
	margin-bottom: 1rem;
	position: relative;
	z-index: 3;
	width: 18rem;
	overflow: hidden;
	.image-container {
		position: relative;
		height: 9rem;
		.overlay{
			position: absolute;
			width: 100%;
			height: 100%;
			background-color: rgba($color: #000, $alpha: 0.25);
			z-index: 1;
		}
	}
	.card-info {
		display: flex;
		flex-direction: column;
		gap: 1rem;
		padding: 0 1rem 1rem;
		position: relative;
		z-index: 2;
		.logo-container {
			width: 4rem;
			height: 4rem;
			border-radius: 50%;
			background-color: #fff;
			position: absolute;
			top: 7rem;
			left: 1.25rem;
			display: flex;
			align-items: center;
			justify-content: center;
			.logo {
				position: relative;
				width: 2.5rem;
				height: 2.5rem;
			}
		}
		.title {
			font-size: 1.15rem;
			min-height: 2rem;
			width: 90%;
			position: absolute;
			top: -3rem;
			
		}
		.details {
			font-size: 1rem;
			color: rgba($color: #fff, $alpha: 0.7);
		}
		.unitsType {
			display: flex;
			align-items: center;
			gap: 1rem;
			.icon-container {
				width: 1.25rem;
				height: 1.25rem;
				position: relative;
			}
			.desc{
				min-height: 3.25rem;
				display: flex;
				align-items: center;
			}
		}
		.buttons-container {
			display: flex;
			align-items: center;
			flex-direction: column-reverse;
			gap: 1rem;
			width: 100%;
			a,
			div {
				width: 100%;
			}
			.button-section {
				width: 100%;
				padding-inline: 0;
			}
		}
	}
	.close-btn {
		position: absolute;
		right: .5rem;
		top: .5rem;
		cursor: pointer;
		z-index: 3;
		svg {
			width: 2rem;
			height: 2rem;
			path {
				fill: #fff;
			}
		}
	}
	&.facility{
		min-height: 18.75rem;
		.card-info{
			.title{
				top: -3.5rem;
			}
			.details{
				-webkit-line-clamp: 5;
			}
		}
	}
}

@media (min-width: 1024px) {
	.masterplan-main-card {
		width: 36rem;
		grid-template-columns: 0.6fr 1fr;
		margin-bottom: 0;
		padding: 1.5rem;
		gap: 2rem;
		.image-container {
			height: 100%;
			.overlay{
				display: none;
			}
		}
		.card-info {
			padding: 0;
			gap: .75rem;
			.details{
				height: 4.5rem;
				-webkit-line-clamp: 3;
				overflow: hidden;
				-webkit-box-orient: vertical;
				display: -webkit-box;
			}
			.title {
				min-height: unset;
				position: unset;
				font-size: 1.3rem;
			}
			.buttons-container {
				flex-direction: row;
				gap: 1rem;
				margin-top: 0;
				width: 100%;
			}
		}
		.close-btn {
			right: 1rem;
			top: 1rem;
			svg {
				path {
					fill: #fff;
				}
			}
		}
		&.facility{
			width: 33rem;
			// min-height: 13rem;
			min-height: unset;
			.card-info{
				.details{
					-webkit-line-clamp: unset;
					height: unset;
				}
			}
		}
	}
}
