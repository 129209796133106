.portfolio-container-mobile {
  background: #31353d;
  background-image: url("/icons/pattern.svg");
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 4rem 0rem;
  gap: 2rem;
  overflow: hidden;
  .header {
    color: white;
    font-size: 2rem;
    line-height: 1;
    padding-inline: 1.25rem;
  }
  .select-container {
    padding-inline: 1.25rem;
    z-index: 2;
    .css-tj5bde-Svg {
      fill: white;
    }
    .css-1n7v3ny-option {
      background-color: grey !important;
      
    }
    .css-yt9ioa-option {
      
    }
    .css-9gakcf-option {
      background-color: grey;
      
    }
  }
  .swiper {
    width: 100%;
    height: 100%;
    padding-inline: 1.25rem;
    .img-container {
      height: 18rem;
      position: relative;
      .overlay {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        background-color: rgba(0, 0, 0, 0.3);
        z-index: 1;
      }
      .slide-data {
        z-index: 2;
        position: absolute;
        padding-inline: 1.375rem;
        color: white;
        width: 100%;
        height: 100%;
        .data {
          position: absolute;
          bottom: 1.5rem;
          display: flex;
          flex-direction: column;
          span {
            text-transform: uppercase;
            font-size: 0.75rem;
            letter-spacing: 0.05em;
            opacity: 0.8;
          }
          h2 {
            font-size: 1.5rem;
            line-height: 1;
            margin-top: .25rem;
          }
          .buttons-group {
            display: flex;
            gap: 1.5rem;
            margin-top: 1.5rem;
            .button-section{
              padding-inline: 0;
              // width: 9rem;
              width: 8rem;
              color: white;
            }
          }
        }
        .logos {
          display: flex;
          justify-content: space-between;
          flex-direction: row;
          align-items: center;
          height: 3.5rem;
          position: relative;
          top: 1.5rem;
          .main-logo {
            position: relative;
            width: 9rem;
            height: 4rem;
            // display: none;
          }
          .link-logo {
            position: relative;
            width: 2rem;
            height: 1.25rem;
          }
        }
      }
    }
  }
  .swiper-navigation-continer {
    margin-top: .75rem;
    width: 100%;
    display: flex;
    // justify-content: flex-end;
    justify-content: space-between;
    flex-direction: row;
    color: white;
    z-index: 2;
    padding-inline: 1.25em;
    .navigation-container {
      display: flex;
      flex-direction: column;
      width: 9.7rem;
      gap: 0.25rem;
      .swiper-pagination-progressbar {
        width: 9.7rem;
        height: 0.2rem;
        background: rgba(255, 255, 255, 0.2);
        position: relative;
        .swiper-pagination-progressbar-fill {
          background-color: #ffffff !important;
          opacity: 1;
        }
      }
      .slide-number-container {
        display: flex;
        color: #ffffff;
        justify-content: space-between;
        font-size: 0.75rem;
        letter-spacing: 0.05em;
        line-height: 1rem;
        z-index: 2;
      }
    }
    .arrows-container {
      display: flex;
      gap: .75rem;
      justify-content: flex-start;
      .portfolio-arrow {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;
        border: 1px solid rgba($color: #ffffff, $alpha: 0.5);
      }
    }
  }
}
