@import "../styles/variables.scss";
@import "./components/home/Banner.scss";
@import "./components/home/SearchSection.scss";
@import "./components/home/Numbers.scss";
@import "./components/home/OurPortfolio.scss";
@import "./components/home/OurPortfolioMobile.scss";
@import "./components/home/Updates.scss";
@import "./components/home/UpdatesMobile.scss";
@import "./components/home/Tomorrow.scss";
@import "./components/common/Button.scss";
@import "./components/common/Navbar.scss";
@import "./components/common/SubNav.scss";
@import "./components/common/StickyNav.scss";
@import "./components/common/MainNav.scss";
@import "./components/common/Footer.scss";
@import "./components/common/ScrollToTop.scss";
@import "./components/common/MobileSearchMenu.scss";
@import "./components/common/MobileMenu.scss";
@import "./components/project/Banner.scss";
@import "./components/project/Amenities.scss";
@import "./components/project/ProjectOverview.scss";
@import "./components/project/Landmarks.scss";
@import "./components/project/SimilarProjects.scss";
@import "./components/project/Facilities.scss";
@import "./components/project/FacilitiesCard.scss";
@import "./components/project/Masterplan.scss";
@import "./components/project/MasterplanMainCard.scss";
@import "./components/phase/Banner.scss";
@import "./components/phase/BuildingType.scss";
@import "./components/phase/BuildingTypeCard.scss";
@import "./components/phase/PhaseOverview.scss";
@import "./components/phase/AboutCard.scss";
@import "./components/common/NavTabs.scss";
@import "./components/common/Amenity.scss";
@import "./components/phase/ConstructionUpdates.scss";
@import "./components/phase/Enquiry.scss";
@import "./components/common/OurPeopleCard.scss";
@import "./components/whoWeAre/OurStory.scss";
@import "./components/OurStory/OurStoryDetails.scss";
@import "./components/OurStory/OurStoryDetailsMobile.scss";
@import "./components/whoWeAre/OurPeople.scss";
@import "./components/whoWeAre/OurPartners.scss";
@import "./components/mediaCenter/MediaCenterSection.scss";
@import "./components/common/MediaCard.scss";
@import "./components/common/CommonBanner.scss";
@import "./components/contact-us/WhereTo.scss";
@import "./components/article/ArticleHeader.scss";
@import "./components/article/ArticleInfo.scss";
@import "./components/common/DropDownMenu.scss";
@import "./components/360works/Overview.scss";
@import "./components/360works/Services.scss";
@import "./components/360works/Commercial.scss";
@import "./components/360works/Collaborations.scss";
@import "./components/360works/Offers.scss";
@import "./components/360works/Testimonials.scss";
@import "./components/common/ComplainForm.scss";
@import "./components/common/ProgressTimer.scss";
@import "./components/common/LoadingPage.scss";
@import "./components/project/SignatureRestaurant.scss";
@import "./components/common/BookingPage.scss";

@font-face {
	font-family: "Akkurat-medium";
	src: url("/fonts/FontsFree-Net-Akkurat-1.ttf");
	font-display: swap;
}
@font-face {
	font-family: "Harshita";
	src: url("/fonts/Harshita.otf");
	font-display: swap;
}
@font-face {
	font-family: "Akkurat-light";
	src: url("/fonts/FontsFree-Net-Akkurat-Light.ttf");
	font-display: swap;
}
@font-face {
	font-family: "Akkurat-bold";
	src: url("/fonts/FontsFree-Net-Akkurat-Bold.ttf");
	font-display: swap;
}
@font-face {
	font-family: "Gotham";
	src: url("/fonts/GothamRnd-Medium.otf");
	font-display: swap;
}
.d-none {
	display: none !important;
}
.no-padding-top {
	padding-top: 1rem !important;
}
.no-opacity {
	opacity: 0 !important;
}
.bold {
	font-family: "Akkurat-bold";
}
.harshita {
	font-family: "Harshita";
}
.gotham {
	font-family: "Gotham";
}

.light {
	font-family: "Akkurat-light";
}
.medium {
	font-family: "Akkurat-medium";
}
html {
	font-size: 16px;
	direction: ltr;
}
html,
body {
	overscroll-behavior-y: none;
	-webkit-overflow-scrolling: touch;
	font-family: "Akkurat-medium" !important;
}
html,
body,
button {
	padding: 0;
	margin: 0;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
}
body {
	&::-webkit-scrollbar {
		display: none !important;
	}
}

body.no-scroll {
	position: fixed;
	overflow: hidden;
	height: 100%;
}

* {
	box-sizing: border-box;
}
//for accessibility
label {
	display: none;
}
a {
	color: inherit;
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	margin: 0;
	font-weight: unset;
}
p,
a,
ul {
	margin: 0;
	padding: 0;
}

.loadingScreen {
	background-color: #31353d;
	position: absolute;
	left: 0;
	right: 0;
	height: 100vh;
	// width: 100vw;
	z-index: 10;
	display: flex;
	align-items: center;
	justify-content: center;
	transform: translateY(-100vh);
	&.website-loading {
		transform: translateY(0vh);
	}
	&.loading {
		transition: transform ease-in-out 0.25s;
		transform: translateY(0);
	}
	&.loading-complete {
		transform: translateY(-100vh);
		transition: transform ease-in-out 1s;
	}
	.loading-message {
		display: flex;
		align-items: center;
		flex-direction: column;
		.loading-title {
			font-size: 1.5rem;
			color: #fff;
			z-index: 100;
			margin-inline-end: 1rem;
		}
		.rings-container {
			width: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 2rem;
		}
		.logo {
			position: relative;
			width: 15rem;
			height: 5rem;
			margin-bottom: 2rem;
		}
	}
}

main {
	transition: all ease-in-out 1s;
	&.routing-start {
		transition: all ease-in-out 0.25s;
	}
}

.not-found-page {
	height: calc(100vh);
	background-color: rgba($color: #000, $alpha: 0.1);
	display: flex;
	justify-content: center;
	align-items: center;
	font-size: 1.2rem;
	.stick {
		margin-inline: 0.25rem;
	}
}

.swiper-button-prev {
	margin: 0 !important;
	padding: 0 !important;
	transition: 0.5s all;
	z-index: 1 !important;
	&::after {
		content: "" !important;
		background-image: url("/icons/arrow--prev.svg");
		width: 35%;
		height: 35%;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
}
.swiper-button-next {
	margin: 0 !important;
	padding: 0 !important;
	transition: 0.5s all;
	z-index: 1 !important;
	&::after {
		content: "" !important;
		background-image: url("/icons/arrow--next.svg");
		width: 35%;
		height: 35%;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
}
.black-arrow-prev {
	margin: 0 !important;
	padding: 0 !important;
	transition: 0.5s all;
	z-index: 1 !important;
	&::after {
		content: "" !important;
		background-image: url("/icons/arrow--prev-black.svg");
		width: 35%;
		height: 35%;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
}
.black-arrow-next {
	margin: 0 !important;
	padding: 0 !important;
	transition: 0.5s all;
	z-index: 1 !important;
	&::after {
		content: "" !important;
		background-image: url("/icons/arrow--next-black.svg");
		width: 35%;
		height: 35%;
		background-size: contain;
		background-position: center;
		background-repeat: no-repeat;
	}
}
.w-100 {
	width: 100%;
}
.swiper-button-next,
.swiper-button-prev {
	position: unset !important;
}

@media (min-width: 1024px) {
	html {
		font-size: 11px;
		overflow-x: hidden;
	}
	body {
		overflow-x: unset !important;
	}
	body.no-scroll {
		height: 100vh;
		overflow: hidden;
	}
	.swiper-button-prev {
		&:hover {
			&:after {
				background-image: url("/icons/arrow--prev-black.svg");
			}
		}
	}
	.swiper-button-next {
		&:hover {
			&:after {
				background-image: url("/icons/arrow--next-black.svg");
			}
		}
	}
	.black-arrow-prev {
		&:hover {
			&:after {
				background-image: url("/icons/arrow--prev.svg");
			}
		}
	}
	.black-arrow-next {
		&:hover {
			&:after {
				background-image: url("/icons/arrow--next.svg");
			}
		}
	}
}
@media (min-width: 1279px) {
	html {
		font-size: 14px;
	}
}

@media (min-width: 1440px) {
	html {
		font-size: 14px;
	}
}

@media (min-width: 1680px) {
	html {
		font-size: 18px;
	}
}

@media (min-width: 1900px) {
	html {
		//from 20px to 18px
		font-size: 18px;
	}
}
