.construction-updates-section {
	height: 40rem;
	position: relative;
	scroll-margin-top: 2rem;
	overflow: hidden;
	&.our-video-section {
		.header {
			z-index: 3;
			display: flex;
			flex-direction: column;
			color: #fff;
			.slogan {
				font-size: 0.6rem;
				letter-spacing: 8px;
				opacity: 0.8;
				width: fit-content;
				overflow: hidden;
			}
			.title {
				margin: 1.5rem 0;
			}
			p {
				width: 40%;
			}
		}
		.container {
			height: 100%;
			position: relative;
			.overlay {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				z-index: 2;
				&.hide-overlay {
					display: none;
				}
				.overlay-bg {
					position: absolute;
					left: 0;
					right: 0;
					top: 0;
					bottom: 0;
					width: 100%;
					height: 100%;
					background-color: rgba($color: #000000, $alpha: 0.3);
				}
			}
			.icon-shadow-container {
				width: 11rem;
				height: 9rem;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%);
				z-index: 5;
				&:hover {
					.icon {
						&.isPlaying {
							display: flex;
						}
					}
				}
				.icon {
					width: 5rem;
					height: 5rem;
					border-radius: 50%;
					mix-blend-mode: normal;
					backdrop-filter: blur(5px);
					display: flex;
					align-items: center;
					justify-content: center;
					cursor: pointer;
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					overflow: hidden;
					.player-container {
						transition: all ease-in-out 0.3s;
						width: 100%;
						height: 100%;
						border: 1px solid #000;
						background-color: #fff;
						border-radius: 50%;
						div {
							width: 100%;
							height: 100%;
							position: relative;
							svg {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: scale(0.95) translate(-50%, -50%);
								path {
									fill: #000;
								}
							}
						}
					}

					&.isPlaying {
						.player-container {
							div {
								svg {
									transform: scale(0.95) translate(-60%, -50%);
								}
							}
						}
						display: none;
					}
				}
			}
			.video-container {
				height: 100%;
				background-color: #000000;

				.video-player {
					object-fit: cover;
				}
			}
		}
	}
&.commercialProject{
	margin-top: 3rem;
}
	.header {
		padding-inline: 1.25rem;
		position: absolute;
		top: 3rem;
		left: 0;
		z-index: 3;
		transition: all ease-in-out 1s;

		h2 {
			font-size: 2rem;
			line-height: 1.1;
			color: #fff;
		}
	}

	.swiper {
		height: 100%;
		position: relative;

		.overlay {
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
			display: flex;
			align-items: center;
			justify-content: center;
			.img-overlay {
				position: absolute;
				left: 0;
				right: 0;
				top: 0;
				bottom: 0;
				width: 100%;
				height: 100%;
				background-color: rgba($color: #000000, $alpha: 0.35);
				z-index: 3;
			}
			&.hide-overlay {
				display: none;
			}
		}

		.icon-shadow-container {
			width: 11rem;
			height: 9rem;
			position: absolute;
			left: 50%;
			top: 50%;
			transform: translate(-50%, -50%);
			z-index: 5;
			&:hover {
				.icon {
					&.isPlaying {
						display: flex;
					}
				}
			}
			.icon {
				width: 5rem;
				height: 5rem;
				border-radius: 50%;
				mix-blend-mode: normal;
				backdrop-filter: blur(5px);
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				overflow: hidden;
				.player-container {
					transition: all ease-in-out 0.3s;
					width: 100%;
					height: 100%;
					border: 1px solid #000;
					background-color: #fff;
					border-radius: 50%;
					div {
						width: 100%;
						height: 100%;
						position: relative;
						svg {
							position: absolute;
							top: 50%;
							left: 50%;
							transform: scale(0.95) translate(-50%, -50%);
							path {
								fill: #000;
							}
						}
					}
				}

				&.isPlaying {
					.player-container {
						div {
							svg {
								transform: scale(0.95) translate(-60%, -50%);
							}
						}
					}
					display: none;
				}
			}
		}

		.video-container {
			height: 100%;
			background-color: #000000;

			.video-player {
				object-fit: cover;
			}
		}

		&.thumbs-swiper {
			width: 100%;
			height: 6rem;
			position: absolute;
			bottom: 3rem;
			left: 0;
			padding-inline-start: 1.25rem;

			.swiper-slide {
				.overlay {
					position: absolute;
					top: 0;
					left: 0;
					right: 0;
					bottom: 0;
					background: rgba($color: #000000, $alpha: 0.25);
					z-index: 1;
				}
				transition: 0.25s all;
				.active {
					.overlay {
						background: none;
					}

					border: solid 1px #fff;
				}
			}

			.thumb-container {
				position: relative;
				height: 100%;
				cursor: pointer;
			}
		}
	}
}

@media (min-width: 1024px) {
	.construction-updates-section {
		// height: 100vh;
		height: calc(100vh - 4rem);
		.header {
			// top: 6rem;
			top: 4rem;
			padding-inline-start: 8.5rem;
			h2 {
				font-size: 3rem;
			}
		}
		.swiper {
			&.thumbs-swiper {
				width: 100%;
				height: 6rem;
				padding-inline-start: 0;
				position: absolute;
				bottom: 3rem;
				left: 8.5rem;
			}
			.icon-shadow-container {
				.icon {
					.player-container {
						background-color: rgba(255, 255, 255, 0.2);
						border: 1px solid rgba(255, 255, 255, 0.2);
						div {
							svg {
								path {
									fill: #fff;
								}
							}
						}
					}
					&:hover {
						.player-container {
							background-color: #fff;
							border: 1px solid #000;
							div {
								svg {
									path {
										fill: #000;
									}
								}
							}
						}
					}
				}
			}
		}
		&.our-video-section {
			.container {
				.icon-shadow-container {
					.icon {
						.player-container {
							background-color: rgba(255, 255, 255, 0.2);
							border: 1px solid rgba(255, 255, 255, 0.2);
							div {
								svg {
									path {
										fill: #fff;
									}
								}
							}
						}
						&:hover {
							.player-container {
								background-color: #fff;
								border: 1px solid #000;
								div {
									svg {
										path {
											fill: #000;
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}
