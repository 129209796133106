.overview-section {
	padding: 3.75rem 1.25rem 4rem;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	.header {
		font-size: 2rem;
		line-height: 1.1;
        margin-bottom: 2rem;
	}
	.description {
		opacity: 0.8;
	}
}
@media (min-width: 1024px) {
	.overview-section {
		padding: 5.75rem 8.5rem 6rem;
		flex-direction: row;
		.header{
			font-size: 3rem;
		}
		div {
			width: 50%;
            .header{
                margin-bottom: 0;
				font-size: 3rem;
            }
		}
	}
}
