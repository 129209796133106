.building-card {
	display: flex;
	flex-direction: column;
	background-color: white;
	.card-img-container {
		.card-img {
			position: relative;
			height: 11.25rem;
			.overlay{
				position: absolute;
				width: 100%;
				height: 100%;
				background-color: rgba(0, 0, 0, 0.3);
				z-index: 1;
			}
		}
	}
	&.businessProject {
		.card-data {
			p {
				display: flex;
				// margin-top: 0.75rem;
				min-height: 4.5rem; 
			}
		}
		.building-details {
			min-height: unset;
		}
	}
	.card-data {
		padding: 1.5rem 0.875rem;
		position: relative;
		z-index: 2;
		h3 {
			font-size: 1.5rem;
			line-height: 1.2;
			position: absolute;
			top: -3rem;
			color: #fff;
		}
		p {
			// display: none;
			padding-bottom: 1.5rem;
		}
	}
	.building-details {
		display: flex;
		flex-direction: column;
		width: 100%;
		margin-bottom: 1.75rem;
		// min-height: 27.5rem;
		.single-section {
			display: flex;
			flex-direction: column;
			.single-data {
				width: 100%;
				display: flex;
				gap: 1rem;
				font-size: 0.9rem;
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
				padding: 0.75rem 0;
				align-items: center;
				.img-container {
					position: relative;
					height: 1.5rem;
					width: 1.5rem;
				}
			}
		}
	}
	.buttons-container {
		display: flex;
		flex-direction: column;
		gap: 0.5rem;
		.downloadBtn {
			min-width: 100%;
			.button-section {
				min-width: 100%;
			}
		}
	}
}
@media (min-width: 1024px) {
	.building-card {
		flex-direction: row-reverse;
		justify-content: space-between;
		.card-img-container {
			padding: 1.5rem 1.5rem 1.5rem 0rem;
			width: 42%;
			.card-img {
				position: relative;
				// width: 30rem;
				height: 100%;
				.overlay{
					display: none;
				}
			}
		}
		&.businessProject {
			.card-data {
				p {
					margin-top: unset;
					min-height: 3.5rem;
				}
				.building-details {
					// min-height: 14.5rem;
					.single-section {
						width: 85%;
						&:last-child {
							width: 0%;
						}
					}
				}
			}
		}
		.card-data {
			padding: 2rem 0rem 2.5rem 2rem;
			width: 54%;
			h3 {
				font-size: 2.5rem;
				margin-bottom: 0.75rem;
				position: unset;
				color: #000;
			}
			p {
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
				min-height: 3.5rem; 
			}
			.building-details {
				flex-direction: row;
				margin-top: 1.5rem;
				margin-bottom: 3rem;
				// min-height: 14.5rem;
				.single-section {
					width: 50%;
					.single-data {
						.img-container {
							width: 1.75rem;
							height: 1.75rem;
						}
					}
				}
			}
			.buttons-container {
				flex-direction: row;
				gap: 1.5rem;
				.downloadBtn {
					min-width: 45%;
				}
			}
		}
	}
}
