.mainnav-container {
	width: 100%;
	background: white;
	height: 4rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-inline: 1.25rem;
	position: relative;
	z-index: 10;
	transition: .5s all;
	&.black-bg {
		background-color: #31353d;
		.burger-menu-container {
			border: 0.5px solid rgba($color: white, $alpha: 0.2);
			.burger-menu {
				span {
					background-color: white;
				}
				&.open {
					span {
						background-color: black;
					}
				}
			}
		}
	}
	.logo-container {
		position: relative;
		height: 2rem;
		width: 10rem;
		cursor: pointer;
	}
	.links-container {
		display: none;
	}
	.book-button {
		display: none;
	}
	.burger-menu-container {
		width: 4rem;
		height: 3.25rem;
		border: 0.5px solid rgba($color: #000000, $alpha: 0.2);
		display: flex;
		justify-content: center;
		align-items: center;
		position: relative;
		z-index: 10;
		.burger-menu {
			width: 1.25rem;
			height: 0.75rem;
			position: relative;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: 0.5s ease-in-out;
			-moz-transition: 0.5s ease-in-out;
			-o-transition: 0.5s ease-in-out;
			transition: 0.5s ease-in-out;
			cursor: pointer;
			span {
				display: block;
				position: absolute;
				height: 2px;
				width: 100%;
				background: #31353d;
				opacity: 0.8;
				left: 0;
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
				-webkit-transition: 0.25s ease-in-out;
				-moz-transition: 0.25s ease-in-out;
				-o-transition: 0.25s ease-in-out;
				transition: 0.25s ease-in-out;
				&:nth-child(1) {
					top: 0px;
				}
				&:nth-child(2) {
					top: 5px;
				}
				&:nth-child(3) {
					top: 5px;
				}
				&:nth-child(4) {
					top: 10px;
				}
			}
			&.open {
				z-index: 50;
				span {
					&:nth-child(1) {
						top: 5px;
						width: 0%;
						left: 50%;
					}
					&:nth-child(2) {
						-webkit-transform: rotate(45deg);
						-moz-transform: rotate(45deg);
						-o-transform: rotate(45deg);
						transform: rotate(45deg);
					}
					&:nth-child(3) {
						-webkit-transform: rotate(-45deg);
						-moz-transform: rotate(-45deg);
						-o-transform: rotate(-45deg);
						transform: rotate(-45deg);
					}
					&:nth-child(4) {
						top: 5px;
						width: 0%;
						left: 50%;
					}
				}
			}
		}
	}
}
@media (min-width: 1024px) {
	.mainnav-container {
		position: unset;
		z-index: unset;
		width: 100%;
		background: white;
		height: 7.6rem;
		padding-inline: 2.5rem;
		display: flex;
		align-items: center;
		&.black-bg {
			.links-container {
				color: rgba(255, 255, 255, 0.85);

				.link {
					&::after {
						background-color: white;
					}
					&:hover,
					&.active {
						color: white;
					}
				}
			}
			.book-button {
				.button-section {
					color: #31353d;
					background-color: white;
					border: 1px solid white;
					&:hover span {
						color: white !important;
					}
					&.button--isi::before {
						background-color: #31353d;
					}
					&.black-button {
						border: 1px solid white !important;
					}
				}
			}
		}
		.logo-container {
			position: relative;
			height: 2.55rem;
			width: 13rem;
			cursor: pointer;
		}
		.links-container {
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			color: #53575a;
			gap: 0.9rem;
			font-size: 1rem;
			.link-wrapper {
				height: 100%;
				display: flex;
				justify-content: center;
				align-items: center;
				&.development {
					cursor: pointer;
				}
			}
			.link {
				display: flex;
				justify-content: center;
				align-items: center;
				cursor: pointer;
				padding-bottom: 0.1rem;
				position: relative;
				&::after {
					position: absolute;
					content: "";
					width: 0;
					height: 2px;
					left: 0;
					bottom: -0.5rem;
					background-color: black;
					transition: all ease-in-out 0.3s;
				}
				// &:hover,
				&.active {
					color: black;
					.icon-container {
						img {
							filter: invert(0%) sepia(78%) saturate(4%) hue-rotate(183deg)
								brightness(106%) contrast(100%) !important;
						}
					}
					&::after {
						width: 100%;
					}
				}
				.icon-container {
					margin-inline-start: 0.5rem;
					position: relative;
					width: 0.75rem;
					height: 0.75rem;
					margin-top: 0.25rem;
				}
			}
		}
		.book-button {
			display: unset;
		}
	}
}
