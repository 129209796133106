.updates-section-mobile {
	padding: 3.75rem 0rem 4rem;
	display: flex;
	flex-direction: column;
	gap: 2rem;
	height: auto;
	&.article {
		background-color: #f7f7f7;
	}
	.header {
		padding-inline: 1.25rem;
		font-size: 2.8rem;
		line-height: 1;
		color: #31353d;
	}
	.updates-header {
		display: flex;
		flex-direction: column;
		gap: 1.5rem;
		color: #31353d;
		padding-inline: 1.25rem;
		overflow: hidden;
		span {
			font-size: 0.6rem;
			letter-spacing: 0.8rem;
		}
		.main-header {
			display: flex;
			flex-direction: column;
			gap: 2.5rem;
			h3 {
				font-size: 2rem;
				line-height: 1;
				margin-inline-start: -0.3rem;
			}
			.see-updates {
				display: flex;
				gap: 0.5rem;
				align-items: center;
				.circle {
					cursor: pointer;
					display: flex;
					justify-content: center;
					align-items: center;
					border-radius: 50%;
					position: relative;
					border: 1px solid black;
					width: 2rem;
					height: 2rem;
					&::before {
						content: "";
						position: absolute;
						width: 0.85rem;
						height: 0.85rem;
						border-radius: 50%;
						background: rgba($color: #000000, $alpha: 0.1);
						border: 1px solid rgba($color: #212c55, $alpha: 0.05);
					}
				}
				span {
					color: #31353d;
					line-height: 1;
					padding-inline-start: 0.25rem;
					font-size: 1.25rem;
					letter-spacing: 0;
				}
			}
		}
	}
	.circle-section {
		padding-inline: 1.25rem;
		display: flex;
		align-items: center;
		gap: 0.5rem;
		.circle {
			cursor: pointer;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 2rem;
			height: 2rem;
			border-radius: 50%;
			background: rgba($color: #000000, $alpha: 0.05);
			position: relative;
			border: 1px solid rgba($color: #212c55, $alpha: 0.05);
			&::before {
				content: "";
				position: absolute;
				width: 0.85rem;
				height: 0.85rem;
				border-radius: 50%;
				background: rgba($color: #000000, $alpha: 0.1);
				border: 1px solid rgba($color: #212c55, $alpha: 0.05);
			}
			&::after {
				content: "";
				position: absolute;
				width: 0.25rem;
				height: 0.25rem;
				border-radius: 50%;
				background: rgba($color: #000000, $alpha: 1);
			}
		}
		span {
			font-size: 1.25rem;
			color: #31353d;
		}
	}
	.swiper {
		width: 100%;
		height: 100%;
		padding-inline: 1.25rem;
		.swiper-img {
			position: relative;
			height: 20rem;
			// width: 20rem;
			justify-content: center;
			align-items: center;
			display: flex;
			font-size: 0.8rem;
			.overlay {
				position: absolute;
				top: 0;
				width: 100%;
				height: 100%;
				z-index: 1;
				background-color: rgba(0, 0, 0, 0.4);
			}
			.tag {
				position: absolute;
				z-index: 1;
				top: 2rem;
				left: 1.5rem;
				text-transform: uppercase;
				color: white;
			}
			.date {
				@extend .tag;
				top: unset;
				bottom: 2rem;
			}
			.circle {
				position: absolute;
				z-index: 1;
				display: flex;
				justify-content: center;
				align-items: center;
				transition: 0.5s all;
				.button-section {
					border: 1px solid white;
				}
			}
		}
		.slide-data {
			width: 100%;
			color: #000000;

			p {
				display: -webkit-box;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
				padding-top: 1.5rem;
				min-height: 6rem;
				font-size: 1.25rem;
			}
			span {
				font-size: 1rem;
			}
		}
	}

	.swiper-navigation-continer {
		margin-top: 0.75rem;
		width: 100%;
		display: flex;
		// justify-content: flex-end;
		justify-content: space-between;
		flex-direction: row;
		color: white;
		z-index: 2;
		padding-inline: 1.25em;
		.navigation-container {
			display: flex;
			flex-direction: column;
			width: 9.7rem;
			gap: 0.25rem;
			margin-top: 0.5rem;
			.swiper-pagination-progressbar {
				width: 9.7rem;
				height: 0.2rem;
				background: rgba(0, 0, 0, 0.1);
				position: relative;
				.swiper-pagination-progressbar-fill {
					background-color: rgba(0, 0, 0, 1) !important;
					opacity: 1;
				}
				&.swiper-pagination-lock {
					display: unset !important;
				}
			}
			.slide-number-container {
				display: flex;
				color: #000;
				justify-content: space-between;
				font-size: 0.75rem;
				letter-spacing: 0.05em;
				line-height: 1rem;
				z-index: 2;
			}
		}
		.arrows-container {
			display: flex;
			gap: 0.75rem;
			justify-content: flex-end;
			.updates-arrow {
				cursor: pointer;
				display: flex;
				justify-content: center;
				align-items: center;
				width: 3rem;
				height: 3rem;
				border-radius: 50%;
				border: 1px solid rgba($color: #31353d, $alpha: 0.5);
			}
		}
	}
}
