.tomorrow-section {
	// background-image: url("/home/IMG-mob.png");
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	padding: 5rem 1.25rem;
	display: flex;
	flex-direction: column;
	color: white;
	position: relative;
	overflow: hidden;
	.background-image {
		position: absolute;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		z-index: -1;
	}
	position: relative;
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		background-color: rgba(0, 0, 0, 0.6);
	}
	p {
		font-size: 1rem;
		opacity: 0.9;
	}
	.header {
		display: flex;
		flex-direction: column;
		gap: 2.5rem;
		position: relative;
		z-index: 1;
		h4 {
			line-height: 1;
			font-size: 2rem;
			white-space: break-spaces;
		}
	}
	.buttons-group {
		padding-top: 2.5rem;
		position: relative;
		z-index: 1;
	}
	.button {
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 0.25rem;
		width: 12rem;
		background-color: white;
		border-radius: 4.3rem;
		padding: 1rem 0rem;
		&:last-child {
			margin-top: 1rem;
		}
		.icon {
			width: 100%;
			height: 1.5rem;
			position: relative;
			cursor: pointer;
		}
	}
}
@media (min-width: 768px) {
	.tomorrow-section {
		.header {
		p {
			width: 60%;
		}
		}
	}
}
@media (min-width: 1024px) {
	.tomorrow-section {
		height: 100vh;
		padding: 0 14rem;
		justify-content: space-between;
		flex-direction: row;
		align-items: center;
		gap: 14rem;
		position: relative;
		.header {
			gap: 1.35rem;
			p {
				width: 60%;
			}
			h4 {
				font-size: 4rem;
			}
		}
		.buttons-group {
			padding-top: 0;
			padding-bottom: 7.5rem;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
			align-items: center;
			height: 100%;
			p {
				// width: 70%;
				margin-bottom: 1.5rem;
			}
		}
		.border-top {
			position: absolute;
			top: 0;
			background-color: rgba(255, 255, 255, 0.7);
			width: 1px;
			height: 25%;
			left: 26.1rem;
			z-index: 1;
		}
		.border-bottom {
			position: absolute;
			bottom: 0;
			left: 26.1rem;
			background-color: rgba(255, 255, 255, 0.7);
			width: 1px;
			height: 20%;
			z-index: 1;
		}
	}
}
