.offers-test-sections {
	padding: 4rem 0 0;
	// background: linear-gradient(to bottom, #f7f7f7 40%, white 0.1%, white 100%);
}
.offers-section {
	.header {
		padding-inline: 1.25rem;
		margin-bottom: 2rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.title {
			
			font-size: 2.8rem;
			line-height: 1;
		}
		.arrows-container {
			display: flex;
			gap: 1.25rem;
			justify-content: flex-start;
			.offers-arrow {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 2.5rem;
				height: 2.5rem;
				border-radius: 50%;
				border: 1px solid rgba($color: #31353d, $alpha: 0.6);
			}
		}
	}
	.swiper {
		padding-inline: 1.25rem;
		.offers-card {
			background-color: white;
			display: flex;
			flex-direction: column;
			box-shadow: 5px 8px 10px 5px rgba(218, 216, 216, 0.4);
			margin-bottom: 4rem;
			.img-container {
				position: relative;
				width: 100%;
				height: 15rem;
			}
            .card-data {
                margin-top: 2rem;
                display: flex;
                flex-direction: column;
                padding-inline: 1rem;
                gap: 1rem;
                .sale {
                    font-size: 2.5rem;
                    line-height: 1.1;
                }
                .title {
                    font-size: 2rem;
                    line-height: 1.15;
					min-height: 4.5rem;
                }
                .description{
                    display: -webkit-box;
                    min-height: 12rem;
                    -webkit-line-clamp: 9;
                    overflow: hidden;
                    -webkit-box-orient: vertical;
					opacity: .85;
					margin-bottom: 2rem;
                }
            }
		}
	}
}
@media (min-width: 1024px) {
	.offers-test-sections {
		padding: 5.75rem 0 0;
	}
	.offers-section {
		.header {
			padding-inline: 8.5rem;
			margin-bottom: 3rem;
			.title {
				font-size: 3rem;
			}
			.arrows-container {
				justify-content: flex-end;
				.offers-arrow {
					width: 3rem;
					height: 3rem;
					&:hover {
						background-color: #31353d;
					}
				}
			}
		}
		.swiper {
			padding-inline: unset;
			padding-inline-start: 8.5rem;
			padding-inline-end: 2rem;
			.offers-card {
				flex-direction: row-reverse;
				justify-content: space-between;
				padding: 1rem;
				margin-bottom: 6rem;
				.img-container {
					width: 40%;
					height: 30rem;
				}
				.card-data {
                    margin-top: unset;
					padding-inline: 2rem;
					gap: 2rem;
					justify-content: center;
                    width: 50%;
					.sale {
						font-size: 3rem;
						line-height: 1.1;
					}
                    .title {
						font-size: 2.75rem;
						line-height: 1.1;
					}
                    .description{
                        min-height: 9rem;
                        -webkit-line-clamp: unset;
						margin-bottom: unset;
                    }
				}
			}
		}
	}
}
