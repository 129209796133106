.phase-banner {
	display: flex;
	width: 100%;
	height: 50rem;
	flex-direction: column;
	background-size: cover;
	background-repeat: no-repeat;
	position: relative;
	padding-inline: 1.25rem;
	padding-top: 6.85rem;
	align-items: center;
	justify-content: center;
	overflow: hidden;
	.banner-image-container {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	.overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		background-color: rgba(0, 0, 0, 0.5);
	}
	.banner-text-data {
		display: flex;
		flex-direction: column;
		color: #ffffff;
		position: relative;
		z-index: 2;
		.text-subtitle {
			font-size: 0.6rem;
			letter-spacing: 8px;
			text-transform: uppercase;
			margin-bottom: 1.5rem;
		}
		.header-container {
			position: relative;
			h1 {
				font-size: 2rem;
				line-height: 1.25;
				margin-inline-start: -0.2rem;
				&:last-child{
					margin-bottom: 1.5rem;
				}
			}
		}
		.text-description {
			font-size: 1rem;
			width: 95%;
			opacity: 0.8;
			margin-bottom: 2.5rem;
		}
		.buttons-group {
			display: flex;
			align-items: center;
			.button-section{
				width: unset;
				padding-inline: 1.25rem;
			}
			.custom-button {
				.button-section {
					border: 0;
					span{
						display:none;
					}
				}
			}
			.button-section span {
				color: white;
			}
			.line {
				width: 1px;
				height: 2rem;
				background-color: rgba(255, 255, 255, 0.7);
				margin-inline-end: 1rem;
			}
			.icon-container {
				position: relative;
				width: 2.25rem;
				height: 1.25rem;
			}
		}
	}
	.map {
		margin-top: 3.5rem;
		display: flex;
		width: 100%;
		justify-content: flex-end;
		position: relative;
		z-index: 2;
		.img-container {
			position: relative;
			width: 5.5rem;
			height: 5rem;
			h4 {
				display: none;
				
			}
		}
	}
}
@media (min-width: 1024px) {
	.phase-banner {
		height: 100vh;
		padding-inline: 8.5rem;
		flex-direction: row;
		justify-content: space-between;
		padding-top: 10.45rem;
		.banner-text-data {
			width: 100%;
			.text-subtitle {
				font-size: 0.7rem;
				letter-spacing: 0.8rem;
			}
			.header-container {
				h1 {
					font-size: 4rem;
					line-height: 1.25;
					position: relative;
					overflow: hidden;
				}
			}
			.text-description {
				width: 50%;
				line-height: 1.75;
				margin-bottom: 4rem;
			}
			.buttons-group {
				.line {
					margin-inline-end: 1.5rem;
				}
				.custom-button {
					.button-section {
						span{
							display: flex;
						}
						.img-container{
							.img{
								transition: 0.3s all;
							}
						}
						&:hover {
							span {
								color: white !important;
								opacity: 0.6;
							}
							.img-container {
								img {
									transition: 0.3s all;
									filter: invert(0.4);
								}
							}
						}
					}
				}
				.downloadBtn {
					.button-section {
						&:hover {
							.img-container {
								img {
									filter: invert(0.8);
								}
							}
						}
					}
				}
				.icon-container {
					cursor: pointer;
					transition: 0.3s all;
					&:hover {
						img {
							transition: 0.3s all;
							filter: invert(0.35);
						}
					}
				}
			}
		}
		.map {
			width: unset;
			margin-top: 8rem;
			.img-container {
				width: 11.5rem;
				height: 10.25rem;
				display: flex;
				justify-content: center;
				align-items: center;
				h4 {
					display: block;
					position: absolute;
					color: white;
					font-size: 0.85rem;
					margin-top: 3rem;
					text-align: center;
					padding-inline: 1rem;
				}
			}
		}
	}
}
