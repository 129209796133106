.numbers-section {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 4rem 1.25rem;
  width: 100%;
  flex-direction: column;
  gap: 2rem;
  .swiper{
    padding-inline: .5rem 2.25rem;
  }
  .single-number {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    
    .number {
      color: #31353d;
      font-size: 2rem;
    }
    .single-number-text {
      color: rgba(49, 53, 61, 0.7);
      font-size: 1rem;
      opacity: 0.8;
    }
  }
}
@media(min-width:1024px){
    .numbers-section {
        padding: 5.75rem 8.5rem 6rem;
        flex-direction: row;
        justify-content: space-around;
        .single-number {
          .number {
            font-size: 3rem;
          }
          .single-number-text{
            font-size: 1.15rem;
          }
        }
      }  
}

