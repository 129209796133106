.booking-page {
    position: relative;
    width: 100%;
    min-height: 45rem;
    .img-container{
        position: absolute;
        width: 100%;
        height: 100%;
        .overlay{
            position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        background-color: rgba($color: #000000, $alpha: .7); 
        z-index: 1;
        }
    }
    .booking{
        position: relative;
        z-index: 2;
        padding-top: 9rem;
        .thank-message{
            padding-top: 10rem;
        }
    }
}
@media (min-width: 1024px) {
	.booking-page {
        height:unset;
        min-height:100vh;
        .booking{
            padding-top: 14rem;
            .thank-message{
                padding-top: 8rem;
            }
        }
	}
}
