.media-card {
	margin-bottom: 2.5rem;
	&:last-child{
		margin-bottom: 0;
	}
	.card-img {
		height: 18rem;
		position: relative;
		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			background: rgba($color: #000000, $alpha: 0.2);
			z-index: 1;
		}
		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			padding: 1rem;
			color: #fff;
			z-index: 2;
			font-size: 0.8rem;
			background-color: rgba(0, 0, 0, 0.3);
			.type {
				text-transform: uppercase;
			}
			.release-date {
			}
		}
		.read-more {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 2;
		}
	}
	.card-details {
		margin-top: 1rem;
		.title {
			font-size: 1.3rem;
			line-height: 1.25;
			min-height: 2.75rem;
			width: 100%;
		}
		.desc {
			margin: 1rem 0;
			line-height: 1.2;
			color: rgba($color: #000000, $alpha: 0.6);
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
		}
	}
}

@media (min-width: 1024px) {
	.media-card {
		padding-inline: 3rem;
		margin-bottom: 3rem;
		height: 30rem;
		&:last-child{
			margin-bottom: 3rem;
		}
		.card-img {
			transition: 0.75s all;
			height: 100%;
			.read-more {
				opacity: 0 !important;
				margin-top: 2rem;
				transition: 0.25s all;
			}
		}
		&:not(:nth-child(3n)) {
			border-inline-end: solid 1px rgba($color: #000000, $alpha: 0.1);
		}
		&:last-of-type {
			border-inline-end: none;
		}
		.card-img {
			&.hoverd {
				height: 60%;
				.read-more {
					opacity: 1 !important;
					margin-top: 0;
				}
			}
		}
		.card-details{
			.desc {
				-webkit-line-clamp: 5;
			}
		}
	}
}
