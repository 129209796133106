.portfolio-container {
	background: linear-gradient(
		to right,
		#fff 8.75%,
		#31353d 0.1%,
		#31353d 91.25%,
		#fff 0.1%,
		#fff 100%
	);
	position: relative;
	padding-bottom: 3rem;
	.white {
		position: absolute;
		top: 0;
		height: 100%;
		width: 8.75%;
		background-color: white;
		left: 0;
	}
	.white1 {
		position: absolute;
		top: 0;
		height: 100%;
		width: 8.75%;
		background-color: white;
		right: 0;
	}
	.bg {
		position: absolute;
		top: 0;
		height: 100%;
		width: 82.5%;
		background-image: url("/icons/pattern.svg");
		background-size: cover;
		background-repeat: no-repeat;
		z-index: 0;
		left: 8.75%;
		display: flex;
		justify-content: center;
		align-items: center;
		// opacity: .7;
		.img-container {
			width: 100%;
			height: 100%;
			position: relative;
		}
	}
	.react-tabs {
		list-style: none;
		.tabs-container {
			display: flex;
			flex-direction: column;
			gap: 4rem;
			.tabs-header {
				padding-top: 3rem;
				padding-inline: calc(6rem + 8.5rem);
				display: flex;
				justify-content: space-between;
				align-items: center;
				z-index: 1;
				h2 {
					color: #fff;
					font-size: 3rem;
					line-height: 1;
				}
				.react-tabs__tab-list {
					list-style: none;
					display: flex;
					gap: 3rem;
					li {
						text-transform: uppercase;
						letter-spacing: 0.05rem;
						color: #ffffff;
						cursor: pointer;
						transition: 0.5s all;
						position: relative;
						&::before {
							position: absolute;
							content: "";
							width: 0;
							height: 2px;
							left: 0;
							bottom: -0.2rem;
							background-color: #fff;
							transition: all ease-in-out 0.3s;
						}
						// 	&:hover {
						// 		&::before {
						// 			position: absolute;
						// 			content: "";
						// 			width: 100%;
						// 			height: 2px;
						// 			left: 0;
						// 			bottom: -0.2rem;
						// 			background-color: #fff;
						// 			transition: all ease-in-out 0.3s;

						// 	}
						// }
					}
					.react-tabs__tab--selected {
						color: white;
						opacity: 0.9;
						outline: none;
						display: flex;
						justify-content: center;
						align-items: center;
						div {
							position: relative;
							&::before {
								position: absolute;
								content: "";
								width: 100%;
								height: 2px;
								left: 0;
								bottom: -0.2rem;
								background-color: #fff;
								transition: all ease-in-out 0.3s;
							}
						}
					}
				}
			}
			.tabs-panels {
				.swiper {
					cursor: url("/icons/dragg.svg"), auto;
					.img-container {
						height: 30rem;
						position: relative;

						.overlay {
							position: absolute;
							top: 0;
							height: 100%;
							width: 100%;
							background-color: rgba(0, 0, 0, 0.3);
							z-index: 1;
						}
						.slide-data {
							z-index: 2;
							position: absolute;
							padding-inline: 4rem;
							color: white;
							width: 100%;
							height: 100%;
							overflow: hidden;
							.data {
								display: flex;
								flex-direction: column;
								gap: 1rem;
								position: absolute;
								bottom: 2rem;
								span {
									text-transform: uppercase;
									font-size: 0.75rem;
									letter-spacing: 0.05em;
								}
								h2 {
									font-size: 2.75rem;
									line-height: 1;
								}
								p {
									width: 65%;
									display: -webkit-box;
									-webkit-line-clamp: 3;
									-webkit-box-orient: vertical;
									overflow: hidden;
									margin-bottom: 0.25rem;
								}
								.buttons-group {
									display: flex;
									gap: 1.5rem;
									.button-section {
										padding-inline: 0;
										width: 9rem;
										color: white;
									}
								}
							}
							.logos {
								display: flex;
								justify-content: space-between;
								align-items: center;
								height: 3.5rem;
								position: relative;
								top: 2rem;
								.main-logo {
									position: relative;
									width: 9rem;
									height: 4rem;
								}
								.link-logo {
									position: relative;
									width: 3rem;
									height: 1.5rem;
								}
							}
						}
					}
				}
				.swiper-navigation-continer {
					margin-top: 4rem;
					width: 100%;
					display: flex;
					justify-content: space-between;
					flex-direction: row;
					color: white;
					z-index: 2;
					padding-inline: 14.5rem;
					align-items: center;
					.navigation-container {
						display: flex;
						flex-direction: column;
						// gap: 0.25rem;
						.swiper-pagination-progressbar {
							width: 9.7rem;
							height: 0.2rem;
							background: rgba(255, 255, 255, 0.2);
							position: relative;
							.swiper-pagination-progressbar-fill {
								background-color: #ffffff !important;
								opacity: 1;
							}
						}
						.slide-number-container {
							display: flex;
							color: #ffffff;
							justify-content: space-between;
							font-size: 0.75rem;
							letter-spacing: 0.05em;
							line-height: 1rem;
							z-index: 2;
						}
					}
					.arrows-container {
						display: flex;
						gap: 1.25rem;
						justify-content: flex-start;
						.portfolio-arrow {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 3rem;
							height: 3rem;
							border-radius: 50%;
							border: 1px solid rgba($color: #ffffff, $alpha: 0.5);
							&:hover {
								background-color: white;
								border: 1px solid rgba($color: #ffffff, $alpha: 1);
							}
						}
					}
				}
				.two-img-container {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 100%;
					height: 100%;
					gap: 1.5rem;
					&.one-img {
						padding-inline: 14.5rem;
						.img-container {
							width: 100%;
						}
					}
					.img-container {
						width: 42rem;
						height: 37rem;
						position: relative;
						cursor: pointer;
						.overlay {
							position: absolute;
							top: 0;
							height: 100%;
							width: 100%;
							background-color: rgba(0, 0, 0);
							opacity: 0.3;
							z-index: 1;
						}
						.slide-data {
							z-index: 2;
							position: absolute;
							padding-inline: 4rem;
							color: white;
							width: 100%;
							height: 100%;
							overflow: hidden;
							.data {
								position: absolute;
								bottom: 2rem;
								display: flex;
								flex-direction: column;
								gap: 1rem;
								span {
									text-transform: uppercase;
									font-size: 0.75rem;
									letter-spacing: 0.05em;
								}
								h2 {
									font-size: 2.75rem;
									line-height: 1;
								}
								p {
									width: 65%;
									display: -webkit-box;
									-webkit-line-clamp: 3;
									-webkit-box-orient: vertical;
									overflow: hidden;
									margin-bottom: 0.25rem;
								}
								.buttons-group {
									display: flex;
									gap: 1.5rem;
									.button-section {
										padding-inline: 0;
										width: 9rem;
										color: white;
									}
								}
							}
							.logos {
								display: flex;
								justify-content: space-between;
								align-items: center;
								height: 3.5rem;
								position: relative;
								width: 100%;
								top: 2rem;
								.main-logo {
									position: relative;
									width: 9rem;
									height: 4rem;
								}
								.link-logo {
									position: relative;
									width: 3rem;
									height: 1.5rem;
								}
							}
						}
					}
				}
			}
		}
	}
	.swiper-wrapper {
		cursor: url("/icons/dragg.svg"), auto;
	}
}
@keyframes opacity {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
