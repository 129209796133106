.nav-tabs-container {
	display: flex;
	align-items: center;
	height: 4rem;
	width: 100%;
	padding-inline: 1.25rem;
	border-bottom: 1px solid rgba(49, 53, 61, 0.2);
	// border-top: 1px solid rgba(49, 53, 61, 0.2);
	position: sticky;
	top: 0rem;
	transition: 0.5s all;
	background-color: white;
	z-index: 4;
	overflow-x: scroll;
	&.postion {
		top: 2.85rem;
		position: sticky;
	}
	&::-webkit-scrollbar {
		display: none;
	}
	&::-moz-scrollbar {
		display: none;
	}
	ul {
		height: 100%;
		list-style: none;
		display: flex;
		gap: 2rem;
		color: #31353d;
		
		li {
			width: max-content;
			cursor: pointer;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			&:after {
				transition: 0.5s all;
				content: "";
				width: 0rem;
				height: 3px;
				background-color: #31353d;
				position: absolute;
				bottom: .5rem;
			}
			&.active {
				position: relative;
				&:after {
					width: 4rem;
				}
			}
		}
	}
}
@media (min-width: 1024px) {
	.nav-tabs-container {
		padding-inline: 8.5rem;
		align-items: center;
		overflow-x: hidden;
		ul {
			width: 100%;
			justify-content: space-between;
			gap: unset;
			li {
				width: unset;
			}
		}
	}
}
