.enquiry-section {
	padding: 3.75rem 0 4rem;
	height: 53.5rem;
	scroll-margin-top: 2rem;
	overflow: hidden;
	position: relative;
	&.booking {
		height: 100%;
		width: 100%;
		.header {
			color: #fff;
		}
		.thank-message {
			color: #fff;
			padding: 0;
		}
		&.formSubmitted {
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	&.businessProject {
		// background: #F7F7F7;
	}
	&.phasePage {
		height: 48.5rem;
	}
	&.contactPage {
		height: 43.5rem;
	}
	&.white-text{
		.header{
			color: #fff;
		}
		.thank-message{
			color: #fff;
		}
	}
	.header {
		margin-bottom: 2rem;
		padding-inline-start: 1.25rem;
		position: relative;
		z-index: 2;
		h2 {
			font-size: 2rem;
			line-height: 1.15;
		}
	}
	.form-wrapper {
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		gap: 2rem;
		padding-inline: 1.25rem;
		position: relative;
		z-index: 2;
		.field-wrapper {
			position: relative;
			&.select {
				z-index: 2;
			}
			&.select1 {
				z-index: 3;
				&.select2{
					z-index: 2;
				}
			}
			.errorMsg {
				position: absolute;
				color: red;
				margin-inline-start: 1rem;
				font-size: 0.75rem;
				transition: 0.3s all;
			}
			label {
				color: #000000;
				font-size: 1.2rem;
				display: none;
			}
			input {
				height: 3rem;
			}
			.input-wrapper {
				position: relative;
				padding-bottom: 0;
				.text-input {
					padding: 0.5rem 1rem;
					background: #f8f8f8;
					width: 100%;
					font-size: 1rem;
					border: solid 1px rgba($color: #000, $alpha: 0.1);
					border-radius: 4px;
					&:focus-visible {
						outline: none;
						border: solid 1px rgba($color: #31353d, $alpha: 0.7);
					}
					&::placeholder {
						color: rgba($color: #31353d, $alpha: 0.4);
						font-size: 0.75rem;
					}
				}
				textarea {
					resize: none;
				}
				&.input-wrapper--flex {
					display: flex;
					margin-right: 0.9rem;
				}
			}
			.field-wrapper-container {
				display: flex;
				align-items: center;
				flex-direction: row;
				padding: 1rem 0;
				&.field-wrapper-container--column {
					flex-direction: column;
					align-items: flex-start;
					padding-top: 0;
					p {
						padding-top: 1.5rem;
						color: #000000;
						font-size: 1.2rem;
					}
					.title {
						color: #5f5f5f;
					}
					.field-wrapper {
						display: flex;
						flex-direction: column;
						.field {
							display: none;
						}
						.file-input__label {
							border: 1px solid #2d477a;
							padding: 0.5rem 1rem;
							margin: 1rem 0;
							color: #2d477a;
							font-size: 1.4rem;
							cursor: pointer;
						}
					}
					span {
						color: #5f5f5f;
						font-size: 1.2rem;
					}
				}
			}
		}

		button {
			width: fit-content;
			min-width: 9rem;
		}
	}
	.thank-message {
		width: 100%;
		height: 100%;
		text-align: center;
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		padding-top: 15rem;
		position: relative;
		z-index: 2;
		h2 {
			font-size: 1.5rem;
		}
	}
	.bg-container {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -1;
		top: 0;
		.overlay {
			position: absolute;
			width: 100%;
			height: 100%;
			z-index: 1;
			background-color: rgba($color: #000, $alpha: 0.5);
		}
	}
}

@media (min-width: 1024px) {
	.enquiry-section {
		padding: 5.75rem 0 6rem;
		height: 48.5rem;
		&.booking {
			height: 100%;
			.header {
				margin-bottom: 3rem;
			}
		}
		&.businessProject {
			margin-top: -0.05rem;
			position: relative;
			z-index: 1;
		}
		&.phasePage {
			height: 48.5rem;
		}
		&.contactPage {
			height: 43rem;
			.thank-message {
				padding-top: 8rem;
			}
		}
		.header {
			margin-bottom: 3rem;
			padding-inline-start: 8.5rem;
			h2 {
				font-size: 3rem;
			}
		}
		.form-wrapper {
			grid-template-columns: repeat(2, 1fr);
			gap: 2.25rem;
			padding-inline: 8.5rem;
			.field-wrapper {
				&.message-input {
					grid-column: 1/3;
				}
				.errorMsg {
					font-size: 1rem;
				}
				.input-wrapper {
					.text-input {
						&::placeholder {
							font-size: 1rem;
						}
					}
				}
				input {
					height: 4rem;
				}
			}
		}
		.thank-message {
			padding-top: 12rem;
			h2 {
				font-size: 2.5rem;
			}
		}
	}
}
