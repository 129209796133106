.collaborations-section {
	background-color: #31353d;
	padding: 3.75rem 0 4rem;
	position: relative;
	overflow: hidden;
	.bg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		.img-container {
			width: 100%;
			height: 100%;
			position: relative;
		}
	}
	.header {
		color: white;
		padding-inline: 1.25rem;
		font-size: 2rem;
		line-height: 1;
		margin-bottom: 2.25rem;
		margin-inline-start: -0.3rem;
		position: relative;
		z-index: 1;
	}
	.swiper {
		padding-inline: 1.25rem;
		position: relative;
		z-index: 1;
		.collabration-card {
			width: 100%;
			display: flex;
			flex-direction: column;
			gap: 1rem;
			.big-img {
				position: relative;
				// width: 90%;
				width: 95%;
				height: 15rem;
			}
			.card-data {
				display: flex;
				flex-direction: column;
				gap: 1rem;
				justify-content: center;
				color: white;
				.logo-container {
					position: relative;
					width: 9rem;
					height: 4rem;
				}
				.title {
					font-size: 1.5rem;
					line-height: 1.1;
					width: 90%;
					min-height: 4.5rem;
				}
				.description {
					width: 80%;
				}
			}
		}
	}
	.swiper-navigation-continer {
		display: flex;
		flex-direction: row;
		align-items: center;
		color: white;
		padding-inline: 1.25rem;
		margin-top: 2rem;
		position: relative;
		z-index: 1;
		justify-content: space-between;
		.arrows-container {
			display: flex;
			gap: 0.75rem;
			justify-content: flex-start;
			.collaborations-arrow {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 2.5rem;
				height: 2.5rem;
				border-radius: 50%;
				border: 1px solid rgba($color: #ffffff, $alpha: 0.5);
			}
		}
		.navigation-container {
			display: flex;
			flex-direction: column;
			width: 9.7rem;
			gap: 0.25rem;
			margin-bottom: .25rem;
			.swiper-pagination-progressbar {
				width: 9.7rem;
				height: 0.2rem;
				background: rgba(255, 255, 255, 0.2);
				position: relative;
				.swiper-pagination-progressbar-fill {
					background-color: #ffffff !important;
					opacity: 1;
				}
			}
			.slide-number-container {
				display: flex;
				color: #ffffff;
				justify-content: space-between;
				font-size: 0.75rem;
				letter-spacing: 0.05em;
				line-height: 1rem;
				z-index: 2;
			}
		}
	}
}
@media (min-width: 1024px) {
	.collaborations-section {
		padding: 5.75rem 0 6rem;
		.header {
			font-size: 3rem;
			padding-inline: 8.5rem;
			margin-bottom: 3rem;
		}
		.swiper {
			padding-inline: 8.5rem 2rem;
			.collabration-card {
				gap: 3rem;
				flex-direction: row;
				.big-img {
					position: relative;
					height: 30rem;
					width: 50%;
				}
				.card-data {
					width: 50%;
					.title {
						font-size: 2.75rem;
						line-height: 1.25;
						min-height: 7rem;
					}
					.description {
						width: 70%;
						min-height: 3rem;
					}
				}
			}
		}
		.swiper-navigation-continer {
			justify-content: space-between;
			width: 100%;
			padding-inline: 8.5rem;
			margin-top: 4rem;
			.navigation-container {
				display: flex;
				flex-direction: column;
				width: 9.7rem;
				gap: 0.25rem;
				.swiper-pagination-progressbar {
					width: 9.7rem;
					height: 0.2rem;
					background: rgba(255, 255, 255, 0.2);
					position: relative;
					.swiper-pagination-progressbar-fill {
						background-color: #ffffff !important;
						opacity: 1;
					}
				}
				.slide-number-container {
					display: flex;
					color: #ffffff;
					justify-content: space-between;
					font-size: 0.75rem;
					letter-spacing: 0.05em;
					line-height: 1rem;
					z-index: 2;
				}
			}
			.arrows-container {
				justify-content: flex-end;
				.collaborations-arrow {
					width: 3rem;
					height: 3rem;
					&:hover {
						background-color: white;
					}
				}
			}
		}
	}
}
