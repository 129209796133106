.testimonials-section {
	padding-inline: 1.25rem;
	overflow: hidden;
	.header {
		margin-bottom: 2rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.title {
			font-size: 2rem;
			line-height: 1;
		}
	}

	.swiper-navigation-continer {
		display: flex;
		flex-direction: row;
		align-items: center;
		color: white;
		margin-bottom: 4rem;
		justify-content: space-between;
		.navigation-container {
			display: flex;
			flex-direction: column;
			width: 9.7rem;
			gap: 0.25rem;
			margin-bottom: 0.25rem;
			.swiper-pagination-progressbar {
				width: 9.7rem;
				height: 0.2rem;
				background: rgba(0, 0, 0, 0.2);
				position: relative;
				.swiper-pagination-progressbar-fill {
					background-color: #000 !important;
					opacity: 1;
				}
			}
			.slide-number-container {
				display: flex;
				color: black;
				display: flex;
				justify-content: space-between;
				font-size: 0.75rem;
				letter-spacing: 0.05em;
				line-height: 1rem;
			}
		}
		.arrows-container {
			display: flex;
			gap: 0.75rem;
			.test-arrow {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 2.5rem;
				height: 2.5rem;
				border-radius: 50%;
				border: 1px solid rgba($color: #31353d, $alpha: 0.6);
			}
		}
	}

	.slider-number {
		display: none;
	}
	.testimonials-card {
		display: flex;
		flex-direction: column;
		margin-bottom: 3rem;
		position: relative;
		.img-section {
			width: 100%;
			display: none;
			.img-container {
				position: relative;
				width: 10rem;
				height: 10rem;
				border-radius: 50%;
				transform: translateX(0.5rem);
			}
		}
		.card-data {
			width: 97%;
			display: flex;
			flex-direction: column;
			.title {
				margin-bottom: 1.5rem;
				font-size: 2rem;
				line-height: 1.1;
				min-height: 4.5rem;
				overflow: hidden;
			}
			.description {
				opacity: 0.85;
				margin-bottom: 1.5rem;
				overflow: hidden;
				height: 26rem;
			}
			.name {
				font-size: 1.25rem;
				margin-bottom: 0.25rem;
			}
			.position {
				text-transform: uppercase;
				letter-spacing: 6px;
				font-size: 0.75rem;
			}
		}
	}
	.progress {
		// width: 100%;
		display: none;
		justify-content: flex-start;
		position: absolute;
		z-index: 1;
		transform: translateY(4.25rem);
		.CircularProgressbar {
			// width: 43%;
			width: 11rem;
		}
	}
}
@media (min-width: 650px) {
	.testimonials-section {
		.testimonials-card {
			.card-data {
				.title {
					min-height: unset;
				}
				.description{
					height: 13rem;
				}

			}
		}
	}
}
@media (min-width: 1024px) {
	.testimonials-section {
		padding-inline: 8.5rem;
		position: relative;
		.header {
			margin-bottom: 4rem;
			.title {
				font-size: 3rem;
			}
		}
		.testimonials-card {
			display: flex;
			flex-direction: row;
			margin-bottom: 6rem;
			.img-section {
				width: 40%;
				display: unset;
				.img-container {
					display: flex;
					width: 12rem;
					height: 12rem;
					transform: unset;
					margin-top: 3.75rem;
				}
			}
			.card-data {
				width: 60%;
				display: flex;
				flex-direction: column;
				.title {
					margin-bottom: 2rem;
					margin-top: unset;
					font-size: 2.75rem;
					line-height: 1.1;
				}
				.description {
					opacity: 0.85;
				}
				.name {
					font-size: 1.25rem;
					margin-bottom: 0.25rem;
				}
				.position {
					text-transform: uppercase;
					letter-spacing: 6px;
					font-size: 0.75rem;
				}
			}
		}
		.slider-number {
			display: block;
			transition: 0.2s all;
			margin-bottom: 2rem;
		}

		.swiper-navigation-continer {
			width: 12rem;
			position: absolute;
			bottom: 6rem;
			justify-content: center;
			margin-bottom: unset;
			z-index: 2;
			.navigation-container {
				display: none;
			}
			.arrows-container {
				.test-arrow {
					width: 3rem;
					height: 3rem;
					&:hover {
						background-color: #31353d;
					}
				}
			}
		}
		.progress {
			width: 13rem;
			position: absolute;
			transform: none;
			z-index: 1;
			top: 10.5rem;
			left: 8rem;
			display: unset;
			.CircularProgressbar {
				width: 100%;
			}
		}
	}
}
