.masterplan-section {
	background-position: center;
	background-size: cover;
	position: relative;
	overflow: hidden;
	height: 32rem;
	scroll-margin-top: 5rem;
	background-color: #f6f6f6;
	.map {
		position: relative;
		width: 100%;
		height: 100%;
		.pins-container {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;
			left: 0;
			.pin-container {
				position: absolute;
				width: 2rem;
				height: 2rem;
				transition: all ease-in-out 0.5s;
				cursor: pointer;
				&::after {
					content: "";
					position: absolute;
					width: 0.75rem;
					height: 0.75rem;
					background-color: #fff;
					border-radius: 50%;
					top: 45%;
					left: 50%;
					transform: translate(-50%, -50%);
				}
				&.active-pin {
					.pin_icon {
						filter: invert(99%) sepia(34%) saturate(0%) hue-rotate(154deg)
							brightness(115%) contrast(100%);
					}
					&::after {
						background-color: #000;
					}
					.pin-info {
						display: none !important;
					}
				}
				.pin-info {
					display: none;
					position: absolute;
					bottom: calc(100% + 0.75rem);
					left: 50%;
					transform: translateX(-50%);
					background-color: #31353d;
					padding: 0.5rem 1rem;
					text-align: center;
					transition: visibility 2s ease-in-out;
					z-index: 3;
					justify-content: center;
					align-items: center;
					gap: 1rem;
					&.postion{
						bottom:-3rem;
						&::after{
							transform: rotate(180deg) translateX(50%);
							bottom: 100%;
							top: unset;
							left: 50%;
						}
					}
					span {
						color: white;
						white-space: nowrap;
					}
					&::after {
						content: "";
						position: absolute;
						top: 100%;
						left: 50%;
						transform: translateX(-50%);
						border: 0.5rem solid transparent;
						border-top-color: #31353d;
					}
					.logo-container {
						position: relative;
						width: 5rem;
						height: 5rem;
						img {
							filter: none;
						}
					}
				}
				&:hover {
					.pin-info {
						display: flex;
						animation: fade-in 1s;
					}
				}
				&:not(:hover) {
					.pin-info {
						animation: fade-out 1s;
					}
				}
				&.facility-pin{
					&:after{
						background-color: rgb(146, 143, 143);
					}
					.pin_icon {
						filter: invert(45%) sepia(34%) saturate(0%) hue-rotate(154deg)
							brightness(115%) contrast(100%);
					}
					.pin_icon {
						filter: invert(99%) sepia(34%) saturate(0%) hue-rotate(154deg)
							brightness(115%) contrast(100%);
					}
					&.active-pin{
						.pin_icon {
							filter: invert(45%) sepia(34%) saturate(0%) hue-rotate(154deg)
								brightness(115%) contrast(100%);
						}
						&::after{
							background-color: #fff;
						}
					}
				}

			}
		}
	}
	.header {
		position: absolute;
		top: 2rem;
		left: 0;
		padding-inline-start: 1.25rem;
		h2 {
			font-size: 2rem;
			line-height: 1.1;
		}
	}
	.masterplan-main-card {
		position: absolute;
		left: 1rem;
		bottom: 1rem;
	}
}

@media (min-width: 1024px) {
	.masterplan-section {
		padding: 0;
		height: calc(100vh - 5rem);
		position: relative;
		.header {
			top: 3.75rem;
			padding-inline-start: 8.5rem;
			h2 {
				font-size: 3rem;
			}
			p {
				width: 50%;
			}
		}
		.map {
			.pins-container {
				.pin-container {
					width: 3rem;
					height: 3rem;
					&::after {
						width: 1rem;
						height: 1rem;
					}
				}
			}
		}
		.masterplan-main-card {
			position: absolute;
			right: unset;
			left: unset;
			bottom: unset;
		}
	}
}
@keyframes fade-out {
	from {
		opacity: 1;
	}

	to {
		opacity: 0;
	}
}
@keyframes fade-in {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}
