.signatures {
	padding: 4rem 0 0;
	margin-bottom: -1.5px;
	scroll-margin-top: 2rem;
	overflow: hidden;
	.header-section {
		padding-inline: 1.25rem;
		margin-bottom: 2rem;
		h5 {
			line-height: 1;
			font-size: 3rem;
			margin-bottom: 1rem;
		}
		p {
			opacity: 0.8;
		}
	}
	.cards-section {
		display: flex;
		flex-wrap: wrap;
		.card {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 50%;
			height: 10rem;
			border: 1px solid rgba(41, 44, 51, 0.1);
			border-inline-end: none;
			.img-container {
				position: relative;
				width: 5rem;
				height: 5rem;
			}
		}
	}
}
@media (min-width: 1024px) {
	.signatures {
		padding: 5.75rem 0 6rem 0;
		margin-bottom: -.1rem;
		.header-section {
			padding-inline: 8.5rem;
			margin-bottom: 4rem;
			h5 {
				margin-bottom: 1.5rem;
			}
			p {
				width: 40%;
			}
		}
		.cards-section {
			.card {
				height: 20rem;
				width: 25%;
				transition: all ease-in-out 0.25s;
				.img-container {
					position: relative;
					width: 7rem;
					height: 7rem;
					img{
						transition: all ease-in-out .5s;
					}
				}
				&:hover{
					background-color: #292c33;
					img {
						filter: invert(99%) sepia(0%) saturate(0%) hue-rotate(201deg)
							brightness(109%) contrast(100%);
					}
				}
			}
		}
	}
}
