.where-to {
	background: #f7f7f7;
	padding: 3.75rem 1.25rem 4rem;
	overflow: hidden;
	h4 {
		font-size: 2rem;
		line-height: 1.15;
	}
	.info-container {
		display: flex;
		flex-direction: column;
		gap: 3rem;
		margin-top: 3rem;
		.info {
			.title {
				font-size: 1.5rem;
			}
			.icons-container {
				.address{
					cursor: unset;
					pointer-events: none;
				}
				.icon {
					display: flex;
					align-items: center;
					margin-top: 1rem;
                    gap: 1rem;
                    width: 100%;
					.img-container {
						width: 1.25rem;
						height: 1.25rem;
						position: relative;
					}
				}
			}
		}
	}
}
@media (min-width: 1024px) {
	.where-to {
		padding: 5.75rem 8.5rem 6rem;
		h4 {
			width: 30%;
			line-height: 1;
			font-size: 3rem;
		}
        .info-container{
            // margin-top: 6rem;
            margin-top: 4rem;
            // flex-direction: row;
            // gap: unset;
			// justify-content: space-between;
			// width: 100%;
			display: grid;
			grid-template-columns: 1.5fr 1fr 1.5fr 1fr;
            .info{
                .title{
                    font-size: 2rem;
                }
				
                .icons-container{
                    .icon{
                        margin-top: 1.5rem;
                        span{
                            width: 80%;
                        }
                    }
                }
            }
        }
	}
}
