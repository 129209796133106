.loader-page {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;
	background: #31353d;
	flex-direction: column;
	padding-bottom: 3rem;
	z-index: 1000;
	width: 100%;
	overflow: hidden;
	transition: 1.75s all;
	position: absolute;
	&.end{
		transform: translateX(500vh);
	}
	&.d-none{
		display: none;
	}
	h2 {
		margin-top: 2rem;
		font-size: 2rem;
		color: white;
	}
	.view {
		animation: scale 2.5s linear;
	}
	.path {
		transition: 4.5s all;
		stroke-width: 3;
		// stroke-dasharray: 0;
		animation: pathDrawer 4s linear;
	}
	.img-container {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: -1;
		opacity: .3;
	}
	.progress{
		margin-top: 1rem;
		height: 2px;
		background-color: #fff;
		position: relative;
		animation: progress 2s linear;
	}
}
@media (min-width: 1024px) {
	.loader-page {
		padding-bottom: 0;
		.view {
			animation: scale 2s linear;
		}
		.path{
			animation: none;
			stroke-dasharray: 0;
			transition: 4.5s all;
			&.draw-path{
				stroke-dasharray: 250;
			}
		}
	}
}
@keyframes scale {
	0% {
		opacity: 0;
		transform: scale(0.5);
	}
	100% {
		opacity: 1;
		transform: scale(1);
	}
}
@keyframes progress {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}
@keyframes pathDrawer {
	0% {
		stroke-dasharray: 0;
	}
	100% {
		stroke-dasharray: 250;
	}
}
