.OurStoryDetails-mobile {
	padding: 3.75rem 0 4rem;
	&.OurStoryDetails--article {
		padding: 0;
		.arrows-container {
			margin-top: 2rem;
		}
	}
	.swiper {
		padding-inline: 1.25rem;
		.media-card {
			margin-bottom: 0 !important;
		}
	}

	.swiper-navigation-continer {
		margin-top: 0.75rem;
		width: 100%;
		display: flex;
		// justify-content: flex-end;
		justify-content: space-between;
		flex-direction: row;
		color: white;
		z-index: 2;
		padding-inline: 1.25em;
		.navigation-container {
			display: flex;
			flex-direction: column;
			width: 9.7rem;
			gap: 0.25rem;
			margin-top: 0.5rem;
			.swiper-pagination-progressbar {
				width: 9.7rem;
				height: 0.2rem;
				background: rgba(0, 0, 0, 0.2);
				position: relative;
				.swiper-pagination-progressbar-fill {
					background-color: rgba(0, 0, 0, 1) !important;
					opacity: 1;
				}
			}
			.slide-number-container {
				display: flex;
				color: #000;
				justify-content: space-between;
				font-size: 0.75rem;
				letter-spacing: 0.05em;
				line-height: 1rem;
				z-index: 2;
			}
		}
		.arrows-container {
			display: flex;
			gap: 0.75rem;
			justify-content: flex-end;
			.contractors-arrow {
				display: flex;
				justify-content: center;
				align-items: center;
				width: 2.5rem;
				height: 2.5rem;
				border-radius: 50%;
				border: 1px solid rgba($color: #31353d, $alpha: 0.6);
			}
		}
	}

	.OurStoryDetails__card {
		width: 100%;
		// padding: 2rem 0;
		.image-container {
			height: 20rem;
			width: 100%;
			position: relative;
			.overlay{
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				background-color: rgba(0, 0, 0, 0.25);
				z-index: 1;
			}
		}
		.content {
			padding: 1.5rem 0 2rem;
			span {
				letter-spacing: 0.4rem;
				font-size: 0.7rem;
			}
			.title {
				margin: 0.5rem 0 1.25rem;
				font-size: 1.75rem;
				line-height: 2rem;
			}
			p {
				font-size: 0.8rem;
				color: #000;
				width: 95%;
			}
		}
	}
}
