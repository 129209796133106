.amenities-section {
	padding: 3.75rem 0 4rem;
	scroll-margin-top: 2rem;
	overflow: hidden;
	&.food-court,&.grey-bg {
		background: #f7f7f7;
		.amenity {
			// pointer-events: none;
			height: 12rem;
			.title {
				display: none;
			}
		}
	}
	.header {
		margin-bottom: 2rem;
		padding-inline-start: 1.25rem;
		h2 {
			font-size: 2rem;
			line-height: 1.1;
			// margin-bottom: 1rem;
		}
		p {
			width: 85%;
			margin-top: 1rem;
		}
	}
	&.commercialServices-section {
		.amenities-swiper-mobile {
			.swiper {
				.swiper-wrapper {
					.swiper-slide {
						.amenity {
							.icon-container {
								width: 12rem;
								height: 8rem;
							}
							.amenity-info {
								color: #292c33 !important;
								display: none;
								transition: 0.25s all;
								.title {
									font-size: 1.5rem;
								}
								.desc {
									color: #292c33 !important;
								}
							}
						}
						&.swiper-slide-active {
							.amenity {
								// background-color: #f6f6f6;
								.amenity-info {
									display: flex;
								}
								.icon-container {
									img {
										filter: unset;
									}
								}
							}
							.back-layer {
								opacity: 0 !important;
							}
						}
					}
				}
			}
		}
	}

	.amenities-swiper-mobile {
		.swiper {
			padding-inline: 1.25rem;
			.swiper-wrapper {
				.swiper-slide {
					// &.swiper-slide-active {
					// 	.amenity {
					// 		background-color: #292c33;
					// 		color: #fff;
					// 		.amenity-info {
					// 			.desc {
					// 				color: rgba($color: #fff, $alpha: 0.5);
					// 				display: flex;
					// 			}
					// 		}

					// 		.icon-container {
					// 			margin-bottom: 1rem;
					// 			img {
					// 				filter: invert(99%) sepia(0%) saturate(0%) hue-rotate(201deg)
					// 					brightness(109%) contrast(100%);
					// 			}
					// 		}

					// 		.back-layer {
					// 			opacity: 1;
					// 		}
					// 	}
					// 	.promise {
					// 		box-shadow: none;
					// 	}
					// }
				}
			}
			.swiper-navigation-continer {
				display: flex;
				flex-direction: row;
				color: black;
				z-index: 2;
				margin-top: 2.5rem;
				justify-content: space-between;
				align-items: center;
				.navigation-container {
					display: flex;
					flex-direction: column;
					width: 9.7rem;
					// gap: 0.25rem;
					margin-bottom: .5rem;
					.swiper-pagination-progressbar {
						width: 9.7rem;
						height: 0.2rem;
						background: rgba(0, 0, 0, 0.1);
						position: relative;
						.swiper-pagination-progressbar-fill {
							background-color: rgba(0, 0, 0, 1) !important;
							opacity: 1;
						}
					}
					.slide-number-container {
						display: flex;
						color: black;
						display: flex;
						justify-content: space-between;
						font-size: 0.75rem;
						letter-spacing: 0.05em;
						line-height: 1rem;
					}
				}
				.arrows-container {
					display: flex;
					gap: 0.75rem;
					.amenities-arrow {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 2.5rem;
						height: 2.5rem;
						border-radius: 50%;
						border: 1px solid rgba($color: #31353d, $alpha: 0.6);
					}
				}
			}
		}
		&.in-view {
			.amenity {
				&.active {
					.one {
						width: 100%;
						&.two {
							width: 1px;
							height: 100%;
						}
						&.three {
							width: 100%;
						}
						&.four {
							width: 1px;
							height: 100%;
						}
					}
				}
			}
		}
	}
	&.promises-section {
		background: #f7f7f7;
	}
}

@media (min-width: 1024px) {
	.amenities-section {
		padding: 5.75rem 0 6rem;
		margin-bottom: -2px;
		padding-inline: 8.5rem;
		&.food-court {
			.amenity {
				height: 11rem;
			}
		}
		.header {
			margin-bottom: 4rem;
			padding-inline: 0;
			h2 {
				font-size: 3rem;
				// margin-top: 2rem;
			}
			p {
				margin-top: 2rem;
				font-size: 1rem;
				width: 60%;
			}
		}

		.amenities-container {
			display: grid;
			grid-template-columns: repeat(4, 1fr);
			position: relative;
			&::after {
				content: "";
				position: absolute;
				top: 0;
				height: 1px;
				width: 0;
				transition: 2.5s all linear;
				background-color: rgba($color: #292c331a, $alpha: 0.2);
			}
			&::before {
				content: "";
				position: absolute;
				top: 0;
				width: 1px;
				height: 0;
				transition: 2.5s all linear;
				background-color: rgba($color: #292c331a, $alpha: 0.2);
			}
			&.in-view {
				&::after {
					width: 100%;
				}
				&::before {
					height: 100%;
				}
				.amenity {
					animation-name: stopHover;
					animation-duration: 10s;
					animation-delay: 0s;
					&::after {
						height: 100%;
					}
					&::before {
						width: 100%;
					}
				}
			}
		}

		&.promises-section {
			padding-inline: 8.5rem;
			padding-bottom: 6rem;
			position: relative;
			z-index: 1;
			.header {
				padding-inline-start: 0;
			}
			.promises-container {
				border-top: none;
				border-bottom: none;
				gap: 1rem;
			}
		}
	}
}
@keyframes stopHover {
	0% {
		pointer-events: none;
	}
	100% {
		pointer-events: visible;
	}
}
