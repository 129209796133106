.ArticleInfo-container {
	padding-inline: 1.25rem;
	.ArticleInfo-wrapper {
		display: grid;
		grid: auto-flow / 1fr;
		span {
			margin-bottom: 1rem;
			font-size: 1rem;
		}
		.social-media {
			display: flex;
			button {
				width: 2.5rem;
			}
			.image-container {
				position: relative;
				width: 1.5rem;
				height: 1.5rem;
				margin-top: 0.6rem;
				margin-inline-end: 0.8rem;
				cursor: pointer;
				img {
					position: absolute;
					width: 100%;
				}
				.copy-text {
					position: absolute;
					background: #000000;
					color: #fff;
					top: -2rem;
					width: 4rem;
					text-align: center;
					left: -1rem;
					z-index: 2;
					border-radius: 4px;

					&::before {
						content: "";
						width: 0;
						height: 0;
						bottom: -0.5rem;
						right: 53.3%;
						transform: translateX(50%);
						position: absolute;
						border-left: 0.5rem solid transparent;
						border-right: 0.5rem solid transparent;
						border-top: 0.5rem solid #000;
					}
				}
			}
		}
		.data {
			.info {
				p {
					padding-bottom: 2rem;
					color: #31353d;
				}
			}
			.title {
				padding: 1rem 0 3rem;
				h3 {
					position: relative;
					color: #31353d;
					font-size: 2rem;
					padding-inline-start: 1rem;
					line-height: 1.15;
					&::before {
						content: "";
						position: absolute;
						width: 2px;
						height: 80%;
						background: #31353d;
						top: 0.5rem;
						left: 0;
					}
				}
			}
			.image-data {
				padding: 2rem 0 4rem;
				.image-container {
					position: relative;
					width: 100%;
					height: 25rem;
					.overlay {
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						z-index: 2;
						background-color: rgba(0, 0, 0, 0.3);
					}
					img {
						position: absolute;
						width: 100%;
						height: 100%;
					}
				}
				.image-details {
					color: #000000;
					font-size: 0.9rem;
				}
			}
		}
	}
}
@media (min-width: 1024px) {
	.ArticleInfo-container {
		padding-inline: 8.5rem;
		.ArticleInfo-wrapper {
			display: flex;
			.social-media {
				.image-container {
					width: 1.5rem;
					height: 1.5rem;
					position: relative;
					margin-top: 0.6rem;
					margin-inline-end: 0.8rem;
					img {
						position: absolute;
						width: 100%;
					}
				}
			}
			.data {
				.info {
					p {
						padding-bottom: 2rem;
						font-size: 1.15rem;
					}
				}
				.title {
					padding: 3rem 0 5rem;
					h3 {
						position: relative;
						font-size: 3rem;
						padding-inline-start: 1rem;
						line-height: 1.2;
						&::before {
							top: 1rem;
						}
					}
				}
				.image-data {
					.image-container {
						height: 30rem;
					}
				}
			}
		}
	}
}
