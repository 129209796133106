.our-story-section {
	padding: 4rem 0rem 0rem;
	display: flex;
	flex-direction: column;
	overflow: hidden;
	&.our-story--service {
		padding: 4rem 0rem 0;
		.description {
			margin-bottom: 0;
		}
	}
	.header {
		padding-inline: 1.15rem;
		font-size: 2rem;
		margin-bottom: 1.5rem;
		line-height: 1.1;
	}
	.description {
		padding-inline: 1.25rem;
		margin-bottom: 2.5rem;
		opacity: 0.8;
	}
}
.who-we-are-story-img {
	display: flex;
	flex-direction: column;
	padding-bottom: 4rem;
	overflow: hidden;
	.details {
		padding-inline: 1.25rem;
		h3 {
			font-size: 2rem;
			line-height: 1;
			margin-bottom: 1.5rem;
		}
		p {
			opacity: 0.8;
			white-space: break-spaces;
		}
	}
	.img-container {
		width: calc(100% - 2.5rem);
		margin: auto;
		height: 22rem;
		position: relative;
		overflow: hidden;
		margin-bottom: 1.5rem;
		img{
			transition: .5s all;
		}
	}
}
@media (min-width: 1024px) {
	.our-story-section {
		padding: 5.75rem 8.5rem 3rem;
		flex-direction: row;
		flex-wrap: wrap;
		&.our-story--service {
			padding: 5.75rem 8.5rem 6rem;
			.description {
				margin-bottom: 0;
			}
		}
		div {
			width: 50%;
			padding-inline: 0 !important;
		}
		.header {
			width: 50%;
			margin-bottom: unset;
			font-size: 3rem;
		}
		.description {
			width: 50%;
			margin-bottom: 5rem;
		}
	}
	.who-we-are-story-img {
		width: 100%;
		padding: 0rem 8.5rem 6rem;
		flex-direction: row;
		.details {
			display: flex;
			flex-direction: column;
			justify-content: flex-start;
			width: calc(100% - 39.25rem);
			margin-bottom: 0;
			padding-inline: 0;
			h3 {
				width: 75%;
				margin-bottom: 2rem;
				font-size: 3rem;
			}
			p {
				width: 90%;
				white-space: break-spaces;
			}
		}
		.img-container {
			position: relative;
			width: 39.25rem;
			height: auto;
			transform: translateY(0.5rem);
			padding-inline: unset;
			margin: unset;

		}
	}
}
