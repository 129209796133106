.mobile-search-menu {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	background-color: #ffffff;
	height: 100%;
	z-index: 20;
	display: flex;
	justify-content: center;
	padding-top: 50%;
	overflow: auto;
	overflow-x: hidden;
	&::-webkit-scrollbar {
		display: none !important;
	}
	&.form {
		padding-top: 17%;
		overflow-y: scroll;
		.booking {
			overflow-y: scroll !important;
			padding-top: 0;
			&::-webkit-scrollbar {
				display: none !important;
			}
		}
	}
	.burger-menu-container {
		position: absolute;
		top: 1.5rem;
		height: 2.85rem;
		width: 30%;
		right: 0;
		padding-inline: 1.25rem;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		z-index: 5;
		cursor: pointer;
		.burger-border {
			border: 1px solid #fff;
			padding: 0.5rem;
		}
		.burger-menu {
			width: 1.5rem;
			height: 1.5rem;
			display: flex;
			justify-content: center;
			align-items: center;
			-webkit-transform: rotate(0deg);
			-moz-transform: rotate(0deg);
			-o-transform: rotate(0deg);
			transform: rotate(0deg);
			-webkit-transition: 0.5s ease-in-out;
			-moz-transition: 0.5s ease-in-out;
			-o-transition: 0.5s ease-in-out;
			transition: 0.5s ease-in-out;
			cursor: pointer;
			span {
				display: block;
				position: absolute;
				height: 2px;
				width: 100%;
				background: #fff;
				opacity: 0.8;
				left: 0;
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
				-webkit-transition: 0.25s ease-in-out;
				-moz-transition: 0.25s ease-in-out;
				-o-transition: 0.25s ease-in-out;
				transition: 0.25s ease-in-out;
				&:nth-child(1) {
					top: 5px;
					width: 0%;
					left: 50%;
				}
				&:nth-child(2) {
					-webkit-transform: rotate(45deg);
					-moz-transform: rotate(45deg);
					-o-transform: rotate(45deg);
					transform: rotate(45deg);
				}
				&:nth-child(3) {
					-webkit-transform: rotate(-45deg);
					-moz-transform: rotate(-45deg);
					-o-transform: rotate(-45deg);
					transform: rotate(-45deg);
				}
				&:nth-child(4) {
					top: 5px;
					width: 0%;
					left: 50%;
				}
			}
		}
	}
	.search-section {
		position: relative;
		// width: 100vw;
		width: 85vw;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 2rem;
		// box-shadow: 5px 8px 10px 5px rgba(218, 216, 216, 0.4);
		background-color: white;
		.search-input {
			padding-bottom: 1rem;
			display: flex;
			flex-direction: column;
			justify-content: center;
			width: 90%;
			border-bottom: 1.5px solid rgba(0, 0, 0, 0.2);
			color: black;
			position: relative;
			&:first-child {
				margin-top: 2rem;
			}
			span {
				position: absolute;
				color: red;
				padding-inline-start: 1rem;
				bottom: 0.5rem;
				font-size: 0.75rem;
			}
			.css-tj5bde-Svg {
				fill: black;
			}
			label {
				text-transform: uppercase;
				color: #000000;
				opacity: 0.3;
				font-size: 0.85rem;
				margin-bottom: -0.5rem;
				padding-inline-start: 1rem;
			}
		}
		.search-button {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			margin-bottom: 2rem;
			.black-button {
				width: 90%;
			}
		}
	}
	.img-container {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 0;
		top: 0;
		left: 0;
		.overlay {
			position: absolute;
			top: 0;
			height: 100%;
			width: 100%;
			background: rgba(0, 0, 0, 0.5);
			z-index: 2;
		}
	}
	.title {
		position: absolute;
		font-size: 2rem;
		color: #fff;
		left: 1rem;
		width: 100%;
		top: 18%;
	}
}

@media (orientation: landscape) {
	.mobile-search-menu {
		padding-top: 20%;
		&.form {
			padding-top: 7%;
		}
		.img-container {
			height: 170%;
		}
		.title {
			top: 15%;
			left: 1.75rem;
		}
	}
}

@media (min-width: 768px) {
	.mobile-search-menu {
		.title{
			top: 28%;
		}
		.search-section{
			width: 90vw;
		}
	}
}

@media (min-width: 1024px) {
	.mobile-search-menu {
		align-items: center;
		padding-top: unset;
		&.form {
			padding-top: unset;
			overflow-y: hidden;
			height: 100vh;
			.booking {
				padding: 0;
				height: unset;
				overflow-y: hidden;
				height: 100%;
				.header {
					margin-bottom: 2rem;
				}
				display: flex;
				justify-content: center;
				flex-direction: column;
			}
		}
		.title {
			left: 4.75rem;
			font-size: 2.5rem;
			top: 25%;
		}
		.img-container {
			height: 100%;
		}
		.burger-menu-container {
			padding-inline: 5.5rem;
			top: 0;
			// width: 13%;
			margin-top: 1rem;
		}
		.search-section {
			width: 80vw;
			height: 6.5rem;
			flex-direction: row;
			gap: 0;
			.search-input {
				height: 80%;
				border-right: 1.5px solid rgba(0, 0, 0, 0.2);
				border-bottom: 0;
				padding-bottom: 0rem;
				&:first-child {
					margin-top: unset;
				}
			}
			.search-button {
				width: 70%;
				height: 100%;
				margin-bottom: unset;
			}
		}
	}
}
