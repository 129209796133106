.sticky-container {
	width: 100%;
	background: #31353d;
	opacity: 0.9;
	height: 2.85rem;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: relative;
	z-index: 50;
	padding-inline-start: 1.25rem;
	&.open-menu {
		position: unset;
		z-index: 0;
	}
	.lang-switcher {
		color: white;
		cursor: pointer;
	}
	.contact-container {
		display: flex;
		flex-direction: row;
		height: 100%;
		.phone {
			cursor: pointer;
			display: flex;
			color: #ffff;
			height: 100%;
			align-items: center;
			padding-inline: 1.5rem;
			margin-inline-end: 0.1rem;
			background-color: #292c33;
			.icon-img {
				margin-inline-end: 0.5rem;
				width: 1rem;
				height: 1rem;
				position: relative;
			}
			span {
				font-size: 1rem;
			}
		}
		.search {
			cursor: pointer;
			display: flex;
			color: #ffff;
			height: 100%;
			align-items: center;
			background-color: #292c33;
			padding-inline: 1.5rem;
			text-transform: capitalize;
			&.booknow {
				background: rgba(255, 255, 255, 0.9);
				color: black;
				opacity: 1;
			}
			.burger-menu {
				width: 1.25rem;
				height: 0.75rem;
				position: relative;
				-webkit-transform: rotate(0deg);
				-moz-transform: rotate(0deg);
				-o-transform: rotate(0deg);
				transform: rotate(0deg);
				-webkit-transition: 0.5s ease-in-out;
				-moz-transition: 0.5s ease-in-out;
				-o-transition: 0.5s ease-in-out;
				transition: 0.5s ease-in-out;
				cursor: pointer;
				span {
					display: block;
					position: absolute;
					height: 2px;
					width: 100%;
					background: white;
					opacity: 0.8;
					left: 0;
					-webkit-transform: rotate(0deg);
					-moz-transform: rotate(0deg);
					-o-transform: rotate(0deg);
					transform: rotate(0deg);
					-webkit-transition: 0.25s ease-in-out;
					-moz-transition: 0.25s ease-in-out;
					-o-transition: 0.25s ease-in-out;
					transition: 0.25s ease-in-out;
					&:nth-child(1) {
						top: 0px;
					}
					&:nth-child(2) {
						top: 5px;
					}
					&:nth-child(3) {
						top: 5px;
					}
					&:nth-child(4) {
						top: 10px;
					}
				}
				&.open {
					z-index: 50;
					span {
						&:nth-child(1) {
							top: 5px;
							width: 0%;
							left: 50%;
						}
						&:nth-child(2) {
							-webkit-transform: rotate(45deg);
							-moz-transform: rotate(45deg);
							-o-transform: rotate(45deg);
							transform: rotate(45deg);
						}
						&:nth-child(3) {
							-webkit-transform: rotate(-45deg);
							-moz-transform: rotate(-45deg);
							-o-transform: rotate(-45deg);
							transform: rotate(-45deg);
						}
						&:nth-child(4) {
							top: 5px;
							width: 0%;
							left: 50%;
						}
					}
				}
			}
			.icon-img {
				width: 1rem;
				height: 1rem;
				position: relative;
			}
			span {
				font-size: 1rem;
			}
		}
	}
	.links-container {
		display: none;
	}
}
@media (min-width: 1024px) {
	.sticky-container {
		padding-inline-start: 0;
		.lang-switcher {
			padding-inline-start: 2.5rem;
		}
		.contact-container {
			.phone {
				margin-inline-end: 2rem;
				padding-inline: 0;
				background-color: unset;
			}
			.search {
				padding-inline: 2.25rem;
				// padding-inline: 1.9rem;
				.icon-img {
					margin-inline-end: 0.5rem;
				}
				span {
					font-size: 1rem;
				}
				&.booknow{
					padding-inline: unset;
					width: 8.5rem;
					display: flex;
					justify-content: center;
					align-items: center;
				}
			}
		}
		.links-container {
			display: flex;
			justify-content: center;
			align-items: center;
			color: rgba(255, 255, 255, 0.9);
			gap: 2rem;
			.link {
				cursor: pointer;
				display: flex;
				align-items: center;
				&.active {
					color: white;
				}
				.icon-container {
					margin-inline-start: 0.5rem;
					position: relative;
					width: 0.75rem;
					height: 0.75rem;
					margin-top: 0.25rem;
					img {
						filter: invert(100%) sepia(0%) saturate(1%) hue-rotate(5deg)
							brightness(107%) contrast(101%) !important;
					}
				}
			}
		}
	}
}
