.banner-section-container {
	height: 50rem;
	position: relative;
	display: flex;
	flex-direction: column;
	background: #31353d;
	.banner-wrapper {
		overflow: hidden;
		width: 100%;
		height: 100%;
		position: relative;
		.banner-img {
			position: absolute;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 0;
			transition: all 0.75s;
			clip-path: polygon(0 0, 0 0, 0 100%, 0% 100%);
			img {
				transition: all 0.75s;
			}
			&.active {
				img {
					transform: scale(1.05);
				}
				clip-path: polygon(0 0, 100% 0, 100% 100%, 0% 100%);
			}
			&.prev {
				clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
			}
			.overlay {
				position: absolute;
				top: 0;
				height: 100%;
				width: 100%;
				background: rgba(0, 0, 0, 0.5);
				z-index: 2;
			}
		}
		.banner-text-data {
			display: flex;
			flex-direction: column;
			position: relative;
			height: 100%;
			justify-content: center;
			color: #ffffff;
			padding-inline: 1.25rem;
			padding-top: 2.5rem;
			z-index: 2;
			.text-subtitle {
				font-size: 0.6rem;
				letter-spacing: 8px;
				margin-bottom: 1.5rem;
				opacity: 0.8;
				width: fit-content;
				overflow: hidden;
			}
			.text-title {
				font-size: 2rem;
				margin-bottom: 1.5rem;
				line-height: 1.25;
				width: fit-content;
				overflow: hidden;
			}
			.text-description {
				font-size: 1rem;
				margin-bottom: 2rem;
				width: 95%;
				opacity: 0.8;
				// display: -webkit-box;
				// -webkit-line-clamp: 4;
				// -webkit-box-orient: vertical;
				// width: fit-content;
				// overflow: hidden;
				// min-height: 5.25rem;
			}
			.text-button {
				display: flex;
				width: fit-content;
				overflow: hidden;
			}
		}
		.swiper-navigation-continer {
			// width: calc(100vw - 17rem);
			width: calc(100vw - 18.25rem);
			display: flex;
			flex-direction: row;
			position: absolute;
			bottom: calc(28.5% - 6.5rem);
			right: 1.25rem;
			color: white;
			z-index: 2;
			align-items: center;
			justify-content: flex-end;
			.navigation-container {
				display: none;
			}
			.arrows-container {
				display: flex;
				gap: 0.75rem;
				justify-content: flex-start;
				position: relative;
				top: 2rem;
				.banner-arrow {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 2.5rem;
					height: 2.5rem;
					border-radius: 50%;
					border: 1px solid rgba($color: #ffffff, $alpha: 1);
				}
			}
		}
	}
}

@media (min-width: 1024px) {
	.banner-section-container {
		flex-direction: row;
		height: 100vh;
		.banner-wrapper {
			overflow: hidden;
			position: relative;
			.progress-top {
				display: none;
			}
			.banner-text-data {
				padding-top: unset;
				flex-direction: column;
				padding-inline: 8.5rem;
				height: 100%;
				overflow: hidden;
				z-index: 2;
				.text-title {
					font-size: 4rem;
					line-height: 1.25;
				}
				.text-description {
					font-size: 1rem;

					margin-bottom: 1.5rem;
					width: 40%;
					opacity: 0.8 !important;
					min-height: 3.5rem;
				}
				.text-subtitle {
					font-size: 0.75rem;
				}
			}
			.swiper-navigation-continer {
				justify-content: space-between;
				//test
				bottom: calc(28.5% - 3rem);
				left: 8.5rem;
				right: unset;
				.navigation-container {
					display: flex;
					flex-direction: column;
					// gap: 0.25rem;
					progress {
						width: 9.7rem;
						height: 0.2rem;
						position: relative;
						transition: 0.25s all ease;
						background: rgba(255, 255, 255, 0.2);
						&::-webkit-progress-bar {
							background: rgba(255, 255, 255, 0.2);
							transition: 0.25s all ease;
						}
						&::-moz-progress-bar {
							background: #fff;
							transition: 0.25s all ease;
						}
						&::-webkit-progress-value {
							background: #fff;
							transition: 0.25s all ease;
						}
						&::-moz-progress-value {
							background: #ffffff !important;
							transition: 0.25s all ease;
						}
					}

					.swiper-pagination-progressbar {
						width: 9.7rem;
						height: 0.2rem;
						background: rgba(255, 255, 255, 0.2);
						position: relative;
						.swiper-pagination-progressbar-fill {
							background-color: #ffffff !important;
							opacity: 1;
						}
					}
					.slide-number-container {
						display: flex;
						color: #ffffff;
						display: flex;
						justify-content: space-between;
						font-size: 0.75rem;
						letter-spacing: 0.05em;
						line-height: 1rem;
					}
				}
				.arrows-container {
					justify-content: flex-end;
					top: unset;
					bottom: 0rem;
					.banner-arrow {
						border: 1px solid rgba($color: #ffffff, $alpha: 1);
						width: 3rem;
						height: 3rem;
						&:hover {
							background-color: white;
							border: 1px solid rgba($color: #ffffff, $alpha: 0.5);
						}
					}
				}
			}
		}
	}
}

// @media (min-width: 1440px) {
// 	.banner-section-container {
// 		 height: 108vh;
// 	}
// }