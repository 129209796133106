.services-section {
	background: #f7f7f7;
	padding: 3.75rem 0 4rem;
	overflow: hidden;

	.react-tabs {
		.header {
			padding-inline: 1.25rem;
			display: flex;
			flex-direction: column;

			.title {
				font-size: 2rem;
				line-height: 1;
			}

			.react-tabs__tab-list {
				list-style: none;
				display: flex;
				gap: 2rem;
				justify-content: space-between;

				li {
					text-transform: uppercase;
					letter-spacing: 0.05rem;
					color: black;
					opacity: 0.6;
					cursor: pointer;
					transition: 0.5s all;
				}

				.react-tabs__tab--selected {
					opacity: 1;
					outline: none;
					transition: 0.5s all;

					div {
						position: relative;

						&::before {
							content: "";
							position: absolute;
							bottom: 2rem;
							width: 0.3rem;
							height: 0.3rem;
							left: 50%;
							transform: translateX(-50%);
							background-color: black;
							transition: 0.5s all;
							opacity: 1;
							border-radius: 50%;
							animation: opacity .5s linear;
						}
					}
				}
			}
		}

		.tabs-panels {
			.tab-data {
				.tab-header {
					padding-inline: 1.25rem;
					color: black;
					margin: 2.5rem 0;
					font-size: 1.5rem;
				}
			}

			.swiper {
				padding-inline: 1.25rem;

				.services-card {
					width: 100%;
					display: flex;
					flex-direction: column;
					gap: 1rem;

					.img-container {
						position: relative;
						// width: 90%;
						width: 95%;
						height: 13rem;
						transition: 0.5s all;
						.downloadBtn{
							transition: 0.5s all; 
							position: absolute;
							transform: translate(75%, -50%);
							top: 50%;
						}
					}

					.title {
						font-size: 1.5rem;
						line-height: 1.1;
					}

					.description {
						width: 95%;
						opacity: .85;
					}
				}
			}

			.swiper-navigation-continer {
				display: flex;
				flex-direction: row;
				align-items: center;
				color: white;
				padding-inline: 1.25rem;
				margin-top: 2rem;
				justify-content: space-between;

				.navigation-container {
					display: flex;
					flex-direction: column;
					width: 9.7rem;
					gap: 0.25rem;
					margin-bottom: .25rem;

					.swiper-pagination-progressbar {
						width: 9.7rem;
						height: 0.2rem;
						background: rgba(0, 0, 0, 0.2);
						position: relative;

						.swiper-pagination-progressbar-fill {
							background-color: #000 !important;
							opacity: 1;
						}
					}

					.slide-number-container {
						display: flex;
						color: black;
						display: flex;
						justify-content: space-between;
						font-size: 0.75rem;
						letter-spacing: 0.05em;
						line-height: 1rem;
					}
				}

				.arrows-container {
					display: flex;
					gap: .75rem;
					justify-content: flex-start;

					.serv-arrow {
						display: flex;
						justify-content: center;
						align-items: center;
						width: 2.5rem;
						height: 2.5rem;
						border-radius: 50%;
						border: 1px solid rgba($color: #31353d, $alpha: .6);
					}
				}
			}
		}
	}
}

@media (min-width: 1024px) {
	.services-section {
		padding: 5.75rem 0 6rem;

		.react-tabs {
			.header {
				padding-inline: 8.5rem;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;

				.title {
					font-size: 3rem;
					margin-bottom: unset;
				}
			}

			.tabs-panels {
				.tab-data {
					.tab-header {
						padding-inline: 8.5rem;
						margin: 3rem 0;
					}
				}

				.swiper {
					padding-inline: 8.5rem 2rem;

					.services-card {
						gap: 1.5rem;

						.img-container {
							width: 100%;
							position: relative;
							&:hover{
								.overlay {
									position: absolute;
									top: 0;
									left: 0;
									width: 100%;
									height: 100%;
									z-index: 2;
									background-color: rgba(0, 0, 0, 0.3);
								}
								background: rgba($color: #000000, $alpha: 0.6);
								.downloadBtn{
									opacity: 1;
									z-index: 3;
								}
							}
							.downloadBtn{
								transition: 0.5s all;
								opacity: 0;
								position: absolute;
								transform: translate(75%, -50%);
								top: 50%;
							}
						}
					}
				}

				.swiper-navigation-continer {
					justify-content: space-between;
					width: 100%;
					padding-inline: 8.5rem;
					margin-top: 3rem;

					.navigation-container {
						display: flex;
						flex-direction: column;
						width: 9.7rem;
						gap: 0.25rem;
						margin-bottom: 0rem;

						.swiper-pagination-progressbar {
							width: 9.7rem;
							height: 0.2rem;
							background: rgba(0, 0, 0, 0.2);
							position: relative;

							.swiper-pagination-progressbar-fill {
								background-color: #000 !important;
								opacity: 1;
							}
						}

						.slide-number-container {
							display: flex;
							color: black;
							display: flex;
							justify-content: space-between;
							font-size: 0.75rem;
							letter-spacing: 0.05em;
							line-height: 1rem;
						}
					}

					.arrows-container {
						justify-content: flex-end;

						.serv-arrow {
							width: 3rem;
							height: 3rem;

							&:hover {
								background-color: #31353d;
							}
						}
					}
				}
			}
		}
	}
}

@keyframes opacity {
	0% {
		opacity: 0;
	}

	100% {
		opacity: 1;
	}
}