//general and mobile
.scroll-to-top {
    position: fixed;
    bottom: 1.5rem;
    right: 2rem;
    left: unset;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2rem;
    height: 2rem;
    z-index: 20;
    background-color: #31353d;
    border: 1px solid rgba(255,255,255,.1);
    box-sizing: content-box;
    cursor: pointer;
    img {
      width: 35%;
      height: 35%;
      object-fit: contain;
    }
  }
  //iPad
  @media (min-width: 1024px) {
  }
  
  // Desktop
  @media (min-width: 1025px) {
  }
  
  //4k
  @media (min-width: 1441px) {
  }
  