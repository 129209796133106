.dev-menu {
	height: 36.5rem;
	width: 100%;
	background: #f9f6f6;
	position: relative;
	z-index: 5;
	justify-content: center;
	display: flex;
	align-items: center;
	box-shadow: 0px 15px 15px -15px #000;
	.development-menu {
		width: 100%;
		height: 100%;
		padding: 2.5rem 0;
		.react-tabs {
			list-style: none;
			.tabs-container {
				display: flex;
				flex-direction: column;
				gap: 2.5rem;
				.tabs-header {
					padding-inline: 2.5rem;
					display: flex;
					justify-content: space-between;
					align-items: center;
					z-index: 1;
					h2 {
						color: #31353d;
						font-size: 2rem;
						line-height: 1;
					}
					.react-tabs__tab-list {
						list-style: none;
						display: flex;
						gap: 3rem;
						transition: 0.5s all;
						li {
							text-transform: uppercase;
							letter-spacing: 0.05rem;
							color: #000;
							// opacity: 0.8;
							cursor: pointer;
							transition: 0.5s all;
							font-size: 0.85rem;
							position: relative;
							&::after {
								position: absolute;
								z-index: 10;
								content: "";
								width: 0;
								height: 2px;
								left: 0;
								bottom: -0.5rem;
								background-color: black;
								transition: all ease-in-out 0.3s;
							}
							// &:hover {
							// 	color: black;
							// 	opacity: 1;
							// 	&::after {
							// 		width: 100%;
							// 	}
							// }
						}
						.react-tabs__tab--selected {
							color: black;
							opacity: 1;
							outline: none;
							display: flex;
							justify-content: center;
							align-items: center;
							transition: 0.5s all;
							position: relative;
							&::after {
								position: absolute;
								z-index: 10;
								content: "";
								width: 100%;
								height: 2px;
								left: 0;
								bottom: -0.5rem;
								background-color: black;
								transition: all ease-in-out 0.3s;
							}
						}
					}
				}
				.tabs-panels {
					.swiper {
						padding-inline: 2.5rem;
						.img-container {
							height: 25rem;
							position: relative;
							.overlay {
								position: absolute;
								top: 0;
								height: 100%;
								width: 100%;
								background-color: rgba(0, 0, 0, 0.25);
								z-index: 1;
							}
							.main-logo {
								position: absolute;
								top: 1.5rem;
								left: 1.5rem;
								z-index: 1;
								transition: 0.25s all;
							}
							.project-name {
								margin-top: 1.5rem;
								font-size: 1.4rem;
								line-height: 1.25;
								width: 85%;
								cursor: pointer;
								transition: 0.25s all;
								position: absolute;
								z-index: 2;
								color: #fff;
								bottom: 1.5rem;
								left: 1.5rem;
							}
							.read-more {
								position: absolute;
								top: 50%;
								left: 50%;
								transform: translate(-50%, -50%);
								z-index: 4;
								color: #fff;
								font-size: 1.1rem;
								opacity: 0;
								margin-top: 20px;
								transition: 0.25s all;
								cursor: pointer;
								.button-section {
									width: 10rem;
								}
							}
							.hoverd {
								position: absolute;
								width: 100%;
								height: 100%;
								z-index: 2;
								.hoverd-items {
									position: absolute;
									transition: 0.25s all;
									background: #f6f6f6;
									&.left {
										left: 0;
										height: 100%;
										width: 0rem;
									}
									&.right {
										right: 0rem;
										height: 100%;
										width: 0;
									}
									&.top {
										top: 0rem;
										width: 100%;
										height: 0;
									}
									&.bottom {
										bottom: 0rem;
										width: 100%;
										height: 0;
									}
								}
							}
							&:hover {
								transition: 0.25s all;
								.hoverd-items {
									transition: 0.25s all;
									&.left,
									&.right {
										width: 1rem;
									}
									&.top,
									&.bottom {
										height: 1rem;
									}
								}
								.main-logo {
									opacity: 0;
								}
								.project-name {
									opacity: 0 !important;
								}
								.read-more {
									margin-top: 0;
									opacity: 1;
								}
							}
						}
					}
					.arrows-container {
						display: flex;
						gap: 1.25rem;
						justify-content: flex-end;
						padding-inline: 2.5rem;
						position: absolute;
						top: 2.5rem;
						right: 0;
						.dropdown-arrow {
							display: flex;
							justify-content: center;
							align-items: center;
							width: 2.5rem;
							height: 2.5rem;
							border-radius: 50%;
							border: 1px solid rgba($color: #000, $alpha: 0.8);
							&:hover {
								background-color: #31353d;
							}
						}
					}
				}
			}
		}
	}
}
@media (min-width: 1300px) {
	.dev-menu {
		height: 34rem;
	}
}
