.button-section {
	// padding: 1rem 1.5rem;
	height: 3.25rem;
	width: 8rem;
	border: 1px solid rgba(255, 255, 255, 0.7);
	border-radius: 0.3rem;
	font-size: 0.75rem;
	color: rgba(255, 255, 255, 0.8);
	letter-spacing: 0.05em;
	text-transform: uppercase;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
	cursor: pointer;
	gap: 0.5rem;
	span {
		width: 100%;
		height: 100%;
		div {
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		position: relative;
		z-index: 2;
		transition: 0.75s all;
	}
	.img-container {
		position: relative;
		width: 1rem;
		height: 1rem;
	}
	&.button--isi::before {
		content: "";
		z-index: -1;
		position: absolute;
		z-index: 2;
		top: 50%;
		right: 100%;
		margin: -15px 2px 0 0;
		width: 50px;
		height: 50px;
		border-radius: 50%;
		//test
		// background: #31353d;
		background: white;
		transform-origin: 0% 50%;
		-webkit-transform: scale3d(1, 2, 1);
		transform: scale3d(1, 2, 1);
		transition: transform 0.3s, opacity 0.3s;
		transition-timing-function: cubic-bezier(0.7, 0, 0.9, 1);
	}
	&.button--isi:hover::before {
		transform: scale3d(9, 9, 1);
		// transform: scale3d(12, 9, 1);
	}
	&.black-button {
		border: 1px solid #31353d;
		color: white;
		background: #31353d;
		&.button--isi::before {
			background-color: #fff;
		}
	}
	&.black-border,
	&.white-border {
		background-color: #fff;
		color: #31353d;
		border: 1px solid #292c33;
	}
	&.black-style-master {
		border-color: solid 1px#fff;
		color: white;
		background: #31353d;
		&.button--isi::before {
			background-color: #fff;
		}
	}
	&.white-button {
		transition: 0.5s all;
		span {
			color: #31353d !important;
			font-size: 0.75rem;
		}
	}
}
@media (min-width: 1024px) {
	.button-section {
		//test
		&:hover {
			span {
				color: #31353d !important;
			}
		}
		&.black-button:hover {
			color: #31353d !important;
			border: 1px solid #31353d !important;
		}
		&.black-style-master {
			&:hover {
				color: #31353d;
				border: 1px solid #fff;
			}
		}
		&.white-button {
			&.button--isi::before {
				background-color: #31353d;
			}
			&:hover {
				span {
					color: #fff !important;
				}
			}
		}
		&.black-border {
			&.button--isi::before {
				background-color: #31353d;
			}
			&:hover {
				span {
					color: white !important;
				}
				color: #fff;
			}
		}
		&.white-border {
			&:hover {
				color: #fff;
				border-color: #fff;
			}
		}
	}
}

@media (min-width: 1400px) {
	.button-section {
		&.button--isi:hover::before {
			transform: scale3d(12, 9, 1);
		}
	}
}
