.progress-timer-container {
    position: absolute;
    top: 6.849rem;
    width: 100%;
    z-index: 2;
    .progress-line-base {
        background-color: rgba(255, 255, 255, 0.6);
        height: 0.25rem;
        z-index: -1;
        position: absolute;
        width: 100%;
    }
    .progress-line-timer {
        height: 0.25rem;
        background-color: #31353D;
        &.animation-trigger {
            animation: timer 10s linear;
        }
    }
}

@media (min-width: 1024px) {
    .progress-timer-container {
        top: 10.45rem;
        width: 100%;
        padding-inline: 8.5rem;
        .progress-line-base {
            width: calc(100% - 17rem);
        }
    }
}

@keyframes timer {
	0% {
		width: 0;
	}
	100% {
		width: 100%;
	}
}