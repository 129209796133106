.our-partners {
	padding: 3.75rem 1.25rem 4rem;
	margin-bottom: -1.5px;
	overflow: hidden;
	.header-section {
		margin-bottom: 2rem;
		h5 {
			line-height: 1;
			font-size: 2rem;
			margin-bottom: 1rem;
		}
		p {
			opacity: 0.8;
		}
	}
	.contractors-section {
		margin-bottom: 3rem;
		&:last-child {
			margin-bottom: 0;
		}
		.header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 2rem;
			h6 {
				font-size: 1.5rem;
			}
		}
		.partner-card {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			height: 9rem;
			border: 1px solid rgba(41, 44, 51, 0.1);
			border-inline-end: none;
			.img-container {
				position: relative;
				width: 50%;
				height: 50%;
			}
		}
		.swiper {
			padding-inline-end: 1px;
		}
		.swiper-slide {
			&:last-of-type {
				.partner-card {
					border-inline-end: 1px solid rgba(41, 44, 51, 0.1);
				}
			}
		}
	}
	.arrows-container {
		display: flex;
		gap: 0.75rem;
		justify-content: flex-end;
		margin-top: 2rem;
		.contractors-arrow {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 2.5rem;
			height: 2.5rem;
			border-radius: 50%;
			border: 1px solid rgba($color: #31353d, $alpha: 0.6);
		}
	}
}
@media (min-width: 1024px) {
	.our-partners {
		padding: 5.75rem 8.5rem 6rem;
		.header-section {
			h5 {
				margin-bottom: 1.5rem;
				font-size: 3rem;
			}
			p {
				width: 40%;
			}
		}
		.contractors-section {
			margin-bottom: 2.5rem;
			.header {
				margin-bottom: 2.5rem;
			}
			.partner-card {
				height: 12rem;
				.img-container {
					position: relative;
					width: 7.3rem;
					height: 7rem;
				}
			}
		}
		.arrows-container {
			justify-content: center;
			align-items: center;
			margin-top: 0;
			.contractors-arrow {
				width: 3rem;
				height: 3rem;
				&:hover {
					background-color: #31353d;
				}
			}
		}
	}
}
