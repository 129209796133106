.OurStoryDetails {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
	padding-inline: 8.5rem;
	justify-content: space-between;
	// gap:2.75rem;
	.OurStoryDetails__card {
		width: 30%;
		.image-container {
			height: 29rem;
			width: 100%;
			position: relative;
			overflow: hidden;
			.overlay{
				position: absolute;
				width: 100%;
				height: 100%;
				top: 0;
				background-color: rgba(0, 0, 0, 0.25);
				z-index: 1;
			}
			img {
				width: 100%;
				height: 100%;
				position: absolute;
			}
		}
		.content {
			padding: 1.5rem 0 5rem;
			span {
				letter-spacing: 0.6rem;
				font-size: 0.7rem;
			}
			.title {
				margin-bottom: 1rem;
				line-height: 1;
				// font-size: 2.25rem;
				font-size: 1.75rem;
			}
			p {
				font-size: 1rem;
				color: #000;
			}
		}
		&:nth-of-type(1),
		&:nth-of-type(4) {
			padding-top: 4rem;
		}
		&:nth-of-type(2),
		&:nth-of-type(5) {
			padding-top: 8rem;
		}
		&.index7 {
			padding-top: 0rem;
		}
		&.index6 {
			transform: translateY(-4rem);
			padding-top: 0;
		}
		&.lastIndex {
			transform: translateY(-8rem);
		}
	}
	&.section--two {
		transform: translateY(-8rem);
		margin-bottom: -7rem;
	}
}
