.article-page {
	padding-top: 6.85rem;
}

.article-header {
	padding: 4rem 1.25rem 2rem;
	.sub-title {
		display: flex;
		gap: 0.5rem;
		align-items: center;

		span {
			font-size: 0.9rem;
		}

		.circle {
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background-color: #1b414c;
		}
	}
	.title {
		font-size: 2rem;
		width: 100%;
		line-height: 1.15;
	}
	.date {
		padding-top: 0.5rem;
		span {
			text-transform: uppercase;
			font-size: 0.9rem;
		}
	}
}
.article-image {
	padding: 0 1.25rem 2rem;
	.image-container {
		width: 100%;
		height: 20rem;
		position: relative;
		.overlay {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			background-color: rgba(0, 0, 0, 0.3);
		}
		img {
			width: 100%;
			height: 100%;
			position: absolute;
		}
	}
}
@media (min-width: 1024px) {
	.article-page {
		padding-top: 10.45rem;
	}

	.article-header {
		padding: 4rem 8.5rem 2rem;
		.title {
			font-size: 3rem;
			// width: 50%;
		}
	}
	.article-image {
		padding: 0 8.5rem 3rem;
		.image-container {
			height: 40rem;
		}
	}
}
