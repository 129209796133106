.phase-overview {
	scroll-margin-top: 2rem;
	padding: 4rem 0rem;
	overflow: hidden;
	&.commercialPage {
		.header {
			margin-bottom: 0rem;
		}
	}
	.header {
		padding-inline: 1.25rem;
		display: flex;
		flex-direction: column;
		margin-bottom: 3rem;
		overflow: hidden;
		h3 {
			font-size: 2rem;
			line-height: 1;
			margin-bottom: 2rem;
		}
		p {
			opacity: 0.8;
		}
	}
	.overview-swiper {
		position: relative;
		z-index: 3;
		.swiper {
			width: 100%;
			padding-inline: 1.25rem;
		}
		.swiper-navigation-continer {
			padding-inline: 1.25rem;
			margin-top: 2rem;
			display: flex;
			justify-content: space-between;
			align-items: center;
			.navigation-container {
				display: flex;
				flex-direction: column;
				margin-bottom: .25rem;
				progress {
					width: 9.7rem;
					height: 0.2rem;
					position: relative;
					transition: 0.25s all ease;
					background: rgba(0, 0, 0, 0.1);
					&::-webkit-progress-bar {
						background: rgba(0, 0, 0, 0.1);
						transition: 0.25s all ease;
					}
					&::-moz-progress-bar {
						background: #000;
						transition: 0.25s all ease;
					}
					&::-webkit-progress-value {
						background: #000;
						transition: 0.25s all ease;
					}
					&::-moz-progress-value {
						background: #0000 !important;
						transition: 0.25s all ease;
					}
				}
				.slide-number-container {
					display: flex;
					color: #000;
					display: flex;
					justify-content: space-between;
					font-size: 0.75rem;
					letter-spacing: 0.05em;
					line-height: 1rem;
				}
			}
			.arrows-container {
				display: flex;
				gap: 0.75rem;
				justify-content: flex-end;
				.about-arrow {
					display: flex;
					justify-content: center;
					align-items: center;
					width: 2.5rem;
					height: 2.5rem;
					border-radius: 50%;
					border: 1px solid rgba($color: #000, $alpha: 0.7);
				}
			}
		}
	}
}
@media (min-width: 1024px) {
	.phase-overview {
		padding: 5.75rem 0 6rem;
		.header {
			padding-inline: 8.5rem 0;
			width: 100%;
			flex-direction: row;
			justify-content: space-between;
			margin-bottom: 4rem;
			align-items: flex-start;

			h3 {
				font-size: 3rem;
				// width: fit-content;
				width: 35%;
				margin-bottom: unset;
			}
			p {
				width: 55%;
				opacity: 1;
				padding-inline-end: 8.5rem;
			}
		}
		.overview-swiper {
			.swiper-navigation-continer {
				position: absolute;
				z-index: 3;
				top: 0;
				left: 53.25%;
				transform: translateX(-50%);
				margin-top: 0;
				padding-inline: 0;
				display: unset;
				.navigation-container {
					display: none;
				}
				.arrows-container {
					.about-arrow {
						width: 3rem;
						height: 3rem;
						&:hover {
							background-color: #31353d;
						}
					}
				}
			}
		}
	}
}
@keyframes down-enter {
	0% {
		clip-path: inset(0 0 0 100%);
	}
	100% {
		clip-path: inset(0);
	}
}

@keyframes down-leave {
	0% {
		clip-path: inset(0);
	}
	100% {
		clip-path: inset(0 100% 0 0);
	}
}

@keyframes prev-down-enter {
	0% {
		clip-path: inset(0);
	}
	100% {
		clip-path: inset(0 0 0 100%);
	}
}

@keyframes prev-down-leave {
	0% {
		clip-path: inset(0 100% 0 0);
	}
	100% {
		clip-path: inset(0);
	}
}
